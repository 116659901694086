import React from "react"
import styled from "styled-components"

const Logo = ({ size = 10, inverted = false, color = "accent1", ...props }) => {
  return (
    <>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 160 160"
        size={size}
        {...props}
        style={{ width: size + "rem", height: size + "rem" }}
      >
        {!inverted && (
          <Path
            color={color}
            d="M11,0c34.8,0,69.7,0,104.5,0c8.5,0,17,0,25.4,0c2,0,4,0,6.1,0c0.4,0,0.9,0,1.3,0c0.2,0,0.5,0,0.7,0
	c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0,0.5,0.1c0.2,0,0.5,0,0.7,0.1c0.7,0.2,1.3,0.3,1.9,0.5c1.2,0.5,2.1,1,3.1,1.7
	c0.2,0.2,0.4,0.4,0.7,0.6c0.4,0.3,0.8,0.7,1.1,1.1c0.2,0.3,0.4,0.5,0.6,0.7c0.5,0.7,0.9,1.4,1.2,2.2c0.2,0.6,0.4,1.2,0.6,1.8
	c0.3,1,0.3,2.3,0.3,3.6c0,1.6,0,3.2,0,4.8c0,5.6,0,11.2,0,16.8c0,29.4,0,58.9,0,88.3c0,6.7,0,13.4,0,20.1c0,1.6,0,3.3,0,4.9
	c0,0.8,0.1,1.8-0.1,2.4c0,0.2,0,0.3,0,0.5c-0.2,0.8-0.3,1.5-0.5,2.1c-0.3,0.8-0.6,1.5-1.1,2.2c-0.2,0.4-0.5,0.7-0.8,1.1
	c-0.3,0.4-0.6,0.7-0.9,1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.5,0.4-1.1,0.9-1.6,1.3c-1,0.7-2.2,1.1-3.6,1.5c-1.1,0.3-2.5,0.3-3.9,0.3
	c-1.9,0-3.8,0-5.7,0c-6.5,0-13,0-19.5,0c-28.6,0-57.1,0-85.7,0c-6.2,0-12.4,0-18.6,0c-1.7,0-3.4,0-5.1,0c-1.6,0-3,0-4.2-0.4
	c-0.6-0.2-1.2-0.4-1.7-0.6c-1.6-0.7-3.2-2-4.2-3.3c-0.5-0.7-1.1-1.4-1.4-2.3c-0.3-0.7-0.6-1.5-0.8-2.3c-0.1-0.4-0.1-0.8-0.2-1.2
	c0-0.2,0-0.4,0-0.6c0-0.1,0-0.2,0-0.3c0-0.2,0-0.5,0-0.8c0-0.5,0-1,0-1.5c0-2,0-4.1,0-6.1c0-8,0-16.1,0-24.1c0-26.4,0-52.8,0-79.2
	c0-6.9,0-13.7,0-20.6c0-1.4,0-2.9,0-4.3c0-0.3,0-0.7,0-1c0-0.2,0-0.4,0-0.5c0-0.1,0-0.2,0-0.3c0-0.1,0-0.3,0-0.4
	c0.1-0.4,0.1-0.8,0.2-1.2c0.2-0.5,0.3-1,0.4-1.5C0.8,7.2,1,6.9,1.1,6.6c0.1-0.2,0.1-0.4,0.2-0.5c0.2-0.4,0.5-0.8,0.7-1.2
	C2.3,4.6,2.5,4.3,2.7,4C2.8,3.9,2.9,3.8,3,3.8C3.2,3.5,3.5,3.2,3.7,3C3.8,3,3.9,2.9,4,2.8c0.3-0.2,0.6-0.5,0.9-0.7
	c0.8-0.6,1.7-1,2.7-1.4c0.5-0.2,1.2-0.3,1.8-0.5c0.2,0,0.4,0,0.6-0.1c0.3,0,0.5,0,0.8-0.1C10.8,0,10.9,0.1,11,0z M11.1,3.8
	c-0.3,0-0.5,0-0.8,0.1C9.8,4,9.3,4.1,8.8,4.3c-1.9,0.8-3.5,2.1-4.3,4C4.3,8.7,4.2,9.2,4,9.8c0,0.3-0.1,0.5-0.1,0.8
	c-0.1,0.3,0,0.8,0,1.1c0,0.6,0,1.3,0,1.9c0,1.6,0,3.1,0,4.7c0,6.8,0,13.5,0,20.3c0,26.6,0,53.2,0,79.7c0,7.6,0,15.2,0,22.7
	c0,2,0,3.9,0,5.8c0,0.5,0,1.1,0,1.6c0,0.3,0,0.5,0,0.8c0,0.3,0.1,0.5,0.1,0.8c0.1,0.5,0.2,1.1,0.4,1.6c1,2.3,2.8,3.8,5.4,4.5
	c0.9,0.3,2.2,0.2,3.4,0.2c2,0,3.9,0,5.8,0c6.4,0,12.8,0,19.1,0c27.9,0,55.8,0,83.7,0c6.6,0,13.3,0,19.9,0c1.8,0,3.6,0,5.4,0
	c1.1,0,2.3,0.1,3.2-0.2c0.9-0.3,1.7-0.5,2.4-1c0.4-0.3,0.8-0.6,1.2-0.9c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0,0.1-0.1,0.2-0.2
	c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.4-0.4,0.5-0.7c0.3-0.4,0.5-0.9,0.7-1.4c0.2-0.5,0.3-1,0.4-1.6c0-0.1,0-0.3,0-0.4
	c0.1-0.4,0-1.1,0-1.6c0-1.3,0-2.5,0-3.8c0-7.5,0-14.9,0-22.4c0-28.8,0-57.6,0-86.4c0-6.1,0-12.1,0-18.2c0-1.5,0-2.9,0-4.4
	c0-1,0-2-0.2-2.8c-0.3-1.1-0.7-2-1.2-2.7c-0.2-0.2-0.4-0.4-0.5-0.6c-0.1,0-0.1-0.1-0.2-0.2c-0.2-0.3-0.6-0.6-0.8-0.8
	c-0.6-0.5-1.3-0.8-2.1-1.2c-0.4-0.2-0.8-0.2-1.3-0.3c-0.2,0-0.4,0-0.6-0.1c-0.3-0.1-0.6,0-1,0c-0.5,0-1,0-1.5,0c-1.9,0-3.9,0-5.8,0
	c-8.8,0-17.6,0-26.3,0C80.2,3.8,45.6,3.8,11.1,3.8z M59,11.5c4.2,0,8.4,0,12.6,0c0.6,0,1.2,0,1.7,0c0.3,0,0.6,0,0.9,0
	c0.1,0,0.2,0,0.3,0c0.3,0.1,0.5,0.1,0.7,0.2c0.4,0.1,0.6,0.4,0.7,0.8c0.1,0.3,0.1,0.8,0,1.1c0,0.2,0,0.4,0,0.5
	c-0.1,0.4-0.1,0.9-0.2,1.3c-0.2,0.6-0.3,1.2-0.5,1.8c-0.6,1.8-1.3,3.3-2.3,4.8c-0.5,0.7-1.1,1.3-1.5,2c-0.1,0.3-0.3,0.7-0.2,1.1
	c0.1,0.4,0.4,0.7,0.6,1.1c0.3,0.4,0.6,0.8,0.8,1.2c0.8,1.2,1.5,2.5,2,3.9c0.4,1,0.7,2.2,0.9,3.3c0.1,0.5,0.1,1.1,0.2,1.7
	c0,0.6,0.1,1.2,0.1,1.9c0,0.1,0,0.3,0,0.4c0,0.2,0,0.4,0,0.6c0,0.1,0,0.4,0,0.5c0,0.3,0,0.7,0,1c0,0.8,0,1.7,0,2.6
	c0,0.7,0,1.4-0.3,1.8c-0.5,0.8-1.9,0.6-3.2,0.6c-2.7,0-5.4,0-8.1,0c-1.1,0-2.4-0.1-3.1,0.4c-0.3,0.2-0.4,0.6-0.5,1
	c0,0.2-0.1,0.7,0,0.9c0.1,0.6,0.2,1,0.6,1.3c0.6,0.4,2.1,0.3,3.2,0.3c2.5,0,4.9,0,7.4,0c0.5,0,1,0,1.5,0c0.2,0,0.5,0,0.8,0
	c0.1,0,0.2,0,0.3,0c0.2,0,0.5,0.1,0.7,0.1c0.3,0.1,0.6,0.4,0.7,0.7c0.2,0.4,0.2,1,0.2,1.6c0,0.8,0,1.6,0,2.4c0,1.2,0,2.3,0,3.5
	c0,1.5,0.1,2.6-1.1,2.9c-0.2,0-0.4,0.1-0.5,0.1c-0.1,0-0.2,0-0.3,0c-0.2,0-0.6,0-0.8,0c-0.5,0-1.1,0-1.6,0c-2.2,0-4.5,0-6.7,0
	c-1.3,0-3-0.2-3.7,0.4c-0.4,0.3-0.4,0.7-0.5,1.2c0,0.2,0,0.7,0,0.9c0.1,0.4,0.2,0.8,0.5,1c0.7,0.5,2,0.4,3.1,0.4c2.7,0,5.4,0,8.2,0
	c1.2,0,2.6-0.1,3.2,0.6c0.5,0.6,0.4,1.9,0.4,2.9c0,1.6,0,3.1,0,4.7c0,0.9,0.1,2-0.3,2.6c-0.2,0.3-0.5,0.5-0.9,0.6
	c-0.4,0.1-1.1,0.1-1.6,0.1c-0.9,0-1.9,0-2.8,0c-3.1,0-6.1,0-9.2,0c-0.5,0-1.1,0-1.6,0c-0.8,0-1.6,0-2.1-0.3
	c-0.4-0.3-0.5-0.7-0.6-1.3c0-0.2,0-0.6,0-0.8c0.1-0.4,0.1-0.7,0.4-1c0.6-0.6,2.1-0.4,3.2-0.4c2.6,0,5.1,0,7.7,0
	c1.2,0,2.6,0.1,3.3-0.4c0.5-0.4,0.7-1.6,0.4-2.4c-0.1-0.4-0.4-0.7-0.8-0.9c-0.7-0.3-2.1-0.2-3.1-0.2c-2.3,0-4.6,0-6.9,0
	c-0.7,0-1.3,0-2,0c-0.5,0-1,0-1.4-0.1c-1.1-0.4-1.1-1.5-1.1-2.9c0-1.2,0-2.3,0-3.5c0-0.8,0-1.6,0-2.4c0-0.6,0-1.2,0.2-1.6
	c0.1-0.3,0.4-0.6,0.7-0.7c0.2-0.1,0.5-0.1,0.7-0.1c0.1,0,0.2,0,0.3,0c0.2,0,0.5,0,0.7,0c0.5,0,1,0,1.5,0c2.4,0,4.9,0,7.3,0
	c1.1,0,2.5,0.1,3.2-0.3c0.4-0.3,0.5-0.7,0.6-1.3c0-0.2,0-0.7,0-0.9c-0.1-0.4-0.2-0.8-0.5-1c-0.7-0.5-1.9-0.4-3-0.4
	c-2.3,0-4.6,0-6.9,0c-0.7,0-1.4,0-2.2,0c-0.4,0-0.8,0-1.2,0c-0.5-0.1-0.9-0.3-1.2-0.6c-0.4-0.6-0.3-1.7-0.3-2.7c0-1.5,0-3,0-4.5
	c0-0.7-0.1-1.8,0.1-2.3c0.1-0.5,0.4-0.8,0.8-1c0.2-0.1,0.5-0.1,0.7-0.1c0.1,0,0.2,0,0.3,0c0.2,0,0.5,0,0.8,0c0.5,0,1.1,0,1.6,0
	c2.4,0,4.8,0,7.2,0c1.2,0,2.5,0.1,3.2-0.4c0.3-0.2,0.4-0.6,0.5-1.1c0-0.1,0-0.3,0-0.4c0-0.3,0-0.6,0-0.9c0-0.3,0-0.7,0-1
	c0-0.3,0-0.6,0-0.8c0-0.2,0-0.4,0-0.6c-0.1-0.6-0.1-1.2-0.3-1.8c-0.2-0.7-0.3-1.4-0.6-2c-0.4-1.1-0.9-2-1.5-2.9
	c-0.5-0.8-1.2-1.9-2.1-2.2c-0.6-0.2-1.5,0.3-1.8,0.5c-0.3,0.1-0.5,0.3-0.8,0.4c-0.2,0.1-0.4,0.2-0.6,0.3c-0.5,0.2-1,0.4-1.6,0.6
	c-0.3,0.1-0.6,0.2-1,0.3c-0.6,0.2-1.3,0.3-2,0.4c-0.2,0-0.5,0-0.7,0.1c-0.4,0.1-1.1,0.1-1.4-0.1c-0.5-0.2-0.6-0.5-0.8-1.1
	c0-0.2-0.1-0.6,0-0.8c0.1-0.6,0.2-1,0.6-1.3c0.4-0.3,0.8-0.3,1.3-0.4c0.1,0,0.3,0,0.4,0c0.7-0.2,1.4-0.3,2-0.5
	c0.7-0.2,1.6-0.4,1.8-1.2c0-0.2-0.1-0.4-0.1-0.5c-0.2-0.3-0.5-0.4-0.9-0.6c-0.6-0.2-1.2-0.4-1.9-0.6c-0.3-0.1-0.6-0.1-0.9-0.2
	c-0.1,0-0.3,0-0.4,0c-0.6-0.2-1.2-0.2-1.6-0.7c-0.4-0.6-0.5-2.3,0.1-2.8c0.5-0.4,1.3-0.4,2-0.3c0.2,0,0.4,0,0.5,0.1
	c0.6,0.2,1.2,0.2,1.8,0.4c1.3,0.4,2.5,0.8,3.6,1.4c0.5,0.2,0.9,0.5,1.5,0.7c0.2,0.1,0.6,0,0.8,0c0.4-0.1,0.8-0.5,1.1-0.8
	c0.2-0.2,0.4-0.4,0.5-0.7c0.3-0.4,0.5-0.8,0.7-1.2c0.4-0.6,0.7-1.2,0.9-2c0.1-0.2,0.2-0.6,0.1-0.9c-0.1-0.3-0.2-0.6-0.5-0.7
	c-0.5-0.3-2.1-0.2-3-0.2c-2.3,0-4.6,0-7,0c-1,0-2.4,0.1-3.1-0.3c-0.7-0.5-0.9-2.1-0.3-2.9c0.2-0.3,0.6-0.4,1-0.5
	C58.6,11.5,58.9,11.5,59,11.5z M85.7,11.5c15.5,0,31,0,46.5,0c3.9,0,7.7,0,11.6,0c0.8,0,1.5,0,2.3,0c0.9,0,1.6,0,2.1,0.4
	c0.5,0.5,0.4,1.6,0.4,2.6c0,2,0,4,0,6c0,8.2,0,16.4,0,24.6c0,5.4,0,10.9,0,16.3c0,1.4,0,2.8,0,4.2c0,0.8,0,1.7-0.3,2.2
	c-0.5,0.8-1.9,0.7-3.2,0.7c-2.8,0-5.6,0-8.4,0c-11.9,0-23.9,0-35.8,0c-2.9,0-5.9,0-8.8,0c-0.6,0-1.1,0-1.7,0c-0.3,0-0.6,0-0.9,0
	c-0.1,0-0.2,0-0.2,0c-0.3,0.1-0.6,0.1-0.8,0.2c-0.4,0.2-0.6,0.5-0.7,1c0,0.1,0,0.2,0,0.4c0,0.1,0,0.5,0,0.6c0.1,0.5,0.2,1,0.5,1.2
	c0.7,0.5,2.4,0.4,3.7,0.4c3,0,6,0,9,0c12.4,0,24.9,0,37.4,0c2.2,0,4.5,0,6.7,0c1,0,2.4-0.1,3,0.3c0.8,0.5,0.9,2.4,0.2,3.1
	c-0.4,0.4-1.2,0.4-2.1,0.4c-0.9,0-1.8,0-2.7,0c-4.4,0-8.7,0-13.1,0c-11.4,0-22.8,0-34.2,0c-2.8,0-5.6,0-8.4,0
	c-1.2,0-2.7,0.2-3.3-0.4c-0.5-0.5-0.4-1.6-0.4-2.6c0-1.6,0-3.2,0-4.7c0-0.4,0-0.8,0-1.2c0-0.2,0-0.4,0-0.6c0-0.1,0-0.2,0-0.3
	c0-0.1,0-0.3,0.1-0.4c0.1-0.4,0.3-0.7,0.6-0.9c0.6-0.4,1.7-0.3,2.6-0.3c1.8,0,3.6,0,5.4,0c9.1,0,18.1,0,27.2,0c2.1,0,4.2,0,6.4,0
	c1.1,0,2.3,0.1,2.9-0.4c0.3-0.2,0.4-0.5,0.5-0.9c0-0.1,0-0.2,0-0.4c0-0.1,0-0.5,0-0.6c-0.1-0.6-0.2-1-0.6-1.3
	c-0.6-0.4-1.6-0.3-2.5-0.3c-1.8,0-3.6,0-5.4,0c-9,0-18,0-26.9,0c-2.2,0-4.4,0-6.5,0c-1,0-2.3,0.1-2.9-0.3c-0.4-0.2-0.5-0.6-0.6-1.1
	c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.6c0-0.4,0-0.8,0-1.2c0-1.6,0-3.1,0-4.7c0-1-0.1-2.1,0.4-2.6c0.5-0.5,1.6-0.4,2.6-0.4
	c1.9,0,3.8,0,5.7,0c7.9,0,15.7,0,23.6,0c3,0,6,0,9,0c0.5,0,1.1,0,1.6,0c0.8,0,1.6,0,2.1-0.3c0.4-0.3,0.6-0.8,0.6-1.6
	c0-0.2,0-0.3,0-0.5c-0.1-0.5-0.3-0.9-0.6-1.2c-0.6-0.4-1.6-0.3-2.5-0.3c-1.8,0-3.6,0-5.4,0c-9,0-18,0-27,0c-2.3,0-4.6,0-6.9,0
	c-1.5,0-2.7,0.1-3.1-1.1c-0.2-0.6-0.1-1.7-0.1-2.5c0-1.6,0-3.2,0-4.8c0-1-0.1-2.1,0.4-2.6c0.5-0.5,1.6-0.4,2.6-0.4
	c1.9,0,3.9,0,5.8,0c8,0,16,0,24,0c2.9,0,5.8,0,8.7,0c1.3,0,2.9,0.2,3.7-0.4c0.4-0.3,0.5-0.9,0.5-1.6c0-0.2,0-0.3,0-0.5
	c-0.1-0.4-0.2-0.8-0.5-1.1c-0.7-0.5-2.1-0.4-3.2-0.4c-2.2,0-4.4,0-6.5,0c-8.8,0-17.5,0-26.3,0c-2,0-4,0-6.1,0c-1.2,0-2.2,0-2.7-0.6
	c-0.4-0.5-0.3-1.6-0.3-2.5c0-1.8,0-3.6,0-5.4c0-1,0-2,0.6-2.5c0.6-0.4,1.8-0.4,2.8-0.4c2.1,0,4.2,0,6.3,0c8.3,0,16.5,0,24.8,0
	c2.5,0,4.9,0,7.4,0c1.2,0,2.6,0.1,3.2-0.4c0.3-0.2,0.4-0.5,0.5-0.9c0-0.1,0-0.2,0-0.4c0-0.1,0-0.5,0-0.6c-0.1-0.6-0.2-1-0.6-1.3
	c-0.6-0.4-1.9-0.3-2.9-0.3c-2.2,0-4.3,0-6.5,0c-8.2,0-16.3,0-24.5,0c-2.3,0-4.7,0-7,0c-0.5,0-1,0-1.5,0c-0.2,0-0.6,0-0.8,0
	c-0.1,0-0.2,0-0.3,0c-0.2,0-0.5-0.1-0.7-0.1c-0.4-0.2-0.7-0.5-0.8-0.9c-0.2-0.8-0.1-1.9,0.3-2.3c0.2-0.2,0.5-0.3,0.9-0.4
	C85.3,11.5,85.6,11.5,85.7,11.5z M13.1,11.5c0.7,0,1.3,0.1,1.7,0.4c0.6,0.6,0.4,2,0.4,3.1c0,2.6,0,5.1,0,7.7c0,1.1-0.1,2.7,0.3,3.3
	c0.3,0.4,0.6,0.5,1.2,0.6c0.2,0,0.6,0,0.8,0c0.1,0,0.2,0,0.4,0c0.3-0.1,0.5-0.2,0.7-0.3c0.7-0.5,0.5-2.1,0.5-3.3c0-2.7,0-5.5,0-8.2
	c0-1.5-0.1-2.8,0.9-3.2c0.2-0.1,0.5-0.1,0.7-0.1c0.3-0.1,0.7,0,0.9,0.1c0.4,0.1,0.8,0.3,1,0.6c0.4,0.6,0.3,2.1,0.3,3.1
	c0,2.5,0,5,0,7.6c0,1.1-0.1,2.6,0.3,3.2c0.3,0.4,0.7,0.5,1.3,0.6c0.2,0,0.5,0,0.6,0c0.1,0,0.2,0,0.4,0c0.4-0.1,0.8-0.3,0.9-0.6
	c0.4-0.7,0.3-2.2,0.3-3.3c0-2.6,0-5.2,0-7.8c0-1-0.1-2.2,0.3-2.8c0.2-0.4,0.5-0.5,1-0.6c0.2,0,0.6-0.1,0.9-0.1
	c0.2,0,0.5,0.1,0.7,0.1c0.3,0.1,0.6,0.4,0.7,0.7c0.1,0.2,0.1,0.5,0.1,0.7c0,0.1,0,0.2,0,0.3c0,0.2,0,0.5,0,0.8c0,0.5,0,1,0,1.6
	c0,3.5,0,6.9,0,10.4c0,0.7,0,1.3,0,2c0,0.5,0,1-0.1,1.4c-0.1,0.2-0.2,0.4-0.3,0.6c-0.4,0.4-1.3,0.4-2.2,0.4c-0.8,0-1.6,0-2.4,0
	c-0.6,0-1.2,0-1.7,0.2c-0.4,0.2-0.7,0.6-0.8,1.1c-0.1,0.2-0.1,0.7,0,1c0,0.2,0,0.4,0.1,0.5c0.2,0.5,0.6,0.8,1.1,1
	c0.4,0.1,0.9,0.1,1.3,0.1c0.8,0,1.5,0,2.2,0c1.3,0,2.3,0,2.6,0.9c0.3,0.6,0.2,2.1,0.2,3c0,2.1,0,4.2,0,6.4c0,7.4,0,14.8,0,22.1
	c0,2.1,0,4.3,0,6.4c0,1.5,0.1,2.6-1.1,3c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.5,0-0.7,0c-0.4-0.1-0.7-0.1-1-0.4
	c-0.5-0.5-0.4-1.7-0.4-2.8c0-1.2,0-2.4,0-3.5c0-7.7,0-15.4,0-23.1c0-1.7,0-3.4,0-5.1c0-1,0-2-0.4-2.6c-0.2-0.3-0.5-0.4-0.9-0.5
	c-0.1,0-0.2,0-0.4,0c-0.1,0-0.5,0-0.6,0c-0.6,0.1-1,0.2-1.3,0.6c-0.4,0.6-0.3,1.6-0.3,2.5c0,1.8,0,3.6,0,5.3c0,7.5,0,14.9,0,22.4
	c0,1.5,0,2.9,0,4.4c0,0.8,0,1.7-0.3,2.2c-0.3,0.5-0.7,0.5-1.3,0.7c-0.1,0-0.4,0-0.5,0c-0.1,0-0.2,0-0.3,0c-0.4-0.1-0.7-0.2-0.9-0.4
	C19,75.2,19,74.2,19,73.3c0-1.7,0-3.5,0-5.2c0-7.9,0-15.8,0-23.8c0-1.2,0-2.4,0-3.6c0-0.9,0-1.7-0.4-2.2c-0.4-0.5-1.2-0.6-2-0.5
	c-0.4,0-0.7,0.2-0.9,0.3c-0.6,0.4-0.5,1.3-0.5,2.3c0,1.7,0,3.3,0,5c0,7.3,0,14.5,0,21.8c0,1.8,0,3.6,0,5.5c0,0.9,0.1,2-0.3,2.5
	c-0.3,0.4-0.7,0.5-1.3,0.6c-0.3,0.1-0.7,0-0.9-0.1c-0.4-0.1-0.8-0.3-1-0.6c-0.3-0.4-0.3-1.2-0.3-1.9c0-1,0-2,0-2.9
	c0-6.2,0-12.4,0-18.6c0-4.1,0-8.1,0-12.2c0-1,0-2,0-3.1c0-0.7,0-1.4,0.3-1.9c0.4-0.6,1.2-0.7,2.2-0.7c0.8,0,1.7,0,2.5,0
	c0.5,0,1.1,0,1.6-0.1c0.5-0.2,0.8-0.6,1-1.1c0-0.1,0-0.2,0-0.4c0-0.3,0-0.8,0-1c-0.1-0.5-0.4-0.9-0.8-1.1c-0.4-0.2-1.1-0.2-1.7-0.2
	c-0.8,0-1.7,0-2.5,0c-0.6,0-1.2,0-1.6-0.2c-0.4-0.2-0.7-0.5-0.8-1c-0.1-0.5-0.1-1.1-0.1-1.6c0-0.9,0-1.9,0-2.8c0-3,0-5.9,0-8.9
	c0-0.6,0-1.1,0-1.7c0-0.3,0-0.6,0-0.9c0-0.1,0-0.2,0-0.3c0.1-0.4,0.1-0.7,0.3-0.9c0.2-0.3,0.4-0.5,0.8-0.6
	C12.6,11.5,13,11.5,13.1,11.5z M35.9,11.5c1,0,1.7,0.2,2,0.9c0.2,0.4,0.2,1.1,0.2,1.7c0,0.8,0,1.6,0,2.5c0,0.6,0,1.2,0.2,1.7
	c0.2,0.4,0.6,0.7,1.1,0.8c0.2,0.1,0.7,0.1,1,0c0.2,0,0.4,0,0.5-0.1c0.5-0.2,0.8-0.6,1-1.1c0.1-0.4,0.1-0.9,0.1-1.3
	c0-0.8,0-1.7,0-2.5c0-0.6,0-1.2,0.2-1.6c0.2-0.4,0.5-0.7,0.9-0.8c0.2-0.1,0.5-0.1,0.8-0.1c0.8,0,1.4,0.2,1.7,0.7
	c0.3,0.4,0.3,1.1,0.3,1.8c0,0.8,0,1.7,0,2.5c0,0.5,0,1.1,0.1,1.6c0.2,0.5,0.5,0.8,0.9,0.9c0.2,0.1,0.4,0.1,0.5,0.1
	c0.2,0,0.7,0,0.9,0c0.6-0.1,0.9-0.3,1.2-0.8c0.2-0.4,0.2-1.1,0.2-1.7c0-0.8,0-1.7,0-2.5c0-0.6,0-1.2,0.2-1.6
	c0.2-0.4,0.5-0.7,0.9-0.8c0.8-0.2,1.9-0.1,2.3,0.3c0.5,0.5,0.4,1.7,0.4,2.8c0,1.8,0,3.5,0,5.3c0,1.3,0,2.4-0.9,2.8
	c-0.4,0.2-1,0.2-1.6,0.2c-0.8,0-1.7,0-2.5,0c-0.6,0-1.2,0-1.7,0.2c-0.4,0.2-0.6,0.5-0.8,0.9c-0.1,0.2-0.1,0.4-0.1,0.5
	c0,0.2,0,0.7,0,0.9c0.1,0.6,0.3,0.9,0.8,1.2c0.4,0.2,1.1,0.2,1.7,0.2c0.8,0,1.7,0,2.5,0c0.6,0,1.2,0,1.6,0.2
	c0.4,0.2,0.7,0.5,0.8,0.9c0.2,0.8,0.1,1.9-0.3,2.3c-0.1,0.1-0.4,0.3-0.6,0.3c-0.4,0.1-0.9,0.1-1.4,0.1c-0.6,0-1.3,0-1.9,0
	c-3.4,0-6.8,0-10.2,0c-0.7,0-1.3,0-2,0c-0.5,0-1,0-1.4-0.1c-0.3-0.1-0.5-0.2-0.7-0.4c-0.4-0.5-0.5-1.6-0.2-2.3
	c0.1-0.3,0.4-0.6,0.7-0.7c0.4-0.2,1-0.2,1.6-0.2c0.8,0,1.7,0,2.5,0c0.6,0,1.2,0,1.7-0.2c0.4-0.2,0.6-0.5,0.8-0.9
	c0.1-0.2,0.1-0.4,0.1-0.5c0-0.2,0-0.7,0-0.9c-0.1-0.6-0.3-0.9-0.8-1.2c-0.4-0.2-1.1-0.2-1.7-0.2c-0.8,0-1.7,0-2.5,0
	c-0.7,0-1.4,0-1.8-0.3c-0.7-0.4-0.7-1.4-0.7-2.5c0-1.7,0-3.5,0-5.2c0-0.8-0.1-1.9,0.2-2.4c0.2-0.4,0.5-0.7,0.9-0.8
	C35.5,11.5,35.8,11.5,35.9,11.5z M135.1,15.2c-0.1,0-0.2,0-0.2,0c-0.2,0-0.4,0-0.5,0.1c-0.5,0.2-0.8,0.6-1,1.1
	c-0.1,0.4-0.1,0.9-0.1,1.4c0,0.8,0,1.7,0,2.5c0,0.6,0,1.2-0.2,1.6c-0.2,0.4-0.5,0.7-1,0.8c-0.4,0.1-0.9,0.1-1.4,0.1
	c-0.7,0-1.5,0-2.2,0c-2.4,0-4.8,0-7.2,0c-7.8,0-15.7,0-23.6,0c-2.2,0-4.4,0-6.6,0c-1.1,0-2.4-0.1-3,0.4c-0.4,0.3-0.7,1.2-0.5,1.9
	c0,0.1,0,0.2,0,0.4c0.1,0.5,0.4,0.9,0.8,1.1c0.6,0.3,1.7,0.2,2.6,0.2c2.6,0,5.2,0,7.8,0c8.4,0,16.8,0,25.1,0c2,0,4.1,0,6.1,0
	c1,0,2.1-0.1,2.7,0.4c0.5,0.5,0.4,1.6,0.4,2.6c0,1.7,0,3.3,0,5c0,0.8,0.1,1.9-0.2,2.5c-0.4,1-1.5,0.9-2.8,0.9c-2,0-3.9,0-5.8,0
	c-9,0-18,0-27,0c-2.1,0-4.2,0-6.4,0c-1.1,0-2.3-0.1-2.9,0.4c-0.4,0.3-0.7,1.2-0.5,1.9c0,0.1,0,0.2,0,0.4c0.1,0.5,0.4,0.9,0.8,1.1
	c0.7,0.3,1.8,0.2,2.7,0.2c2.8,0,5.5,0,8.3,0c8.2,0,16.5,0,24.7,0c2,0,4,0,6,0c1,0,2.1-0.1,2.6,0.4c0.5,0.5,0.4,1.6,0.4,2.6
	c0,1.5,0,3,0,4.6c0,0.5,0,1,0,1.4c0,0.3,0,0.5,0,0.8c-0.1,0.6-0.2,1-0.6,1.3c-0.6,0.4-1.6,0.3-2.6,0.3c-1.9,0-3.7,0-5.6,0
	c-9.2,0-18.3,0-27.5,0c-2,0-4,0-6.1,0c-1,0-2.1-0.1-2.7,0.3c-0.8,0.5-0.9,2.2-0.3,3c0.4,0.5,1.2,0.5,2.1,0.5c1,0,2,0,3,0
	c4.9,0,9.8,0,14.7,0c5.9,0,11.9,0,17.8,0c1.6,0,3.1,0,4.6,0c0.8,0,1.8-0.1,2.3,0.3c0.7,0.4,0.7,1.3,0.7,2.4c0,0.8,0,1.6,0,2.4
	c0,0.6,0,1.2,0.2,1.7c0.1,0.4,0.5,0.6,0.8,0.7c0.6,0.2,1.8,0.1,2.6,0.1c1.6,0,3.2,0,4.7,0c1.5,0,2.6,0.1,3-1
	c0.1-0.4,0.1-0.9,0.1-1.4c0-0.7,0-1.4,0-2c0-2.5,0-4.9,0-7.4c0-8.5,0-17.1,0-25.6c0-2.7,0-5.4,0-8.1c0-1.2,0.1-2.8-0.4-3.5
	c-0.4-0.6-1.6-0.5-2.7-0.5C139.5,15.2,137.3,15.2,135.1,15.2z M138.7,19.1c0.8,0,1.5,0.1,1.8,0.5c0.4,0.5,0.4,1.5,0.4,2.4
	c0,1.6,0,3.2,0,4.8c0,8.3,0,16.6,0,24.9c0,2,0,3.9,0,5.9c0,1,0.1,2.1-0.3,2.7c-0.3,0.4-0.7,0.5-1.3,0.6c-0.1,0-0.4,0-0.5,0
	c-0.1,0-0.2,0-0.3,0c-0.4-0.1-0.8-0.2-1-0.5c-0.4-0.6-0.4-1.7-0.4-2.7c0-2,0-3.9,0-5.9c0-8,0-15.9,0-23.9c0-1.8,0-3.7,0-5.5
	c0-0.9-0.1-2,0.3-2.6c0.2-0.3,0.4-0.5,0.8-0.6C138.3,19.1,138.6,19.1,138.7,19.1z M36.2,34.3c4,0,8.1,0,12.2,0c0.8,0,1.6,0,2.4,0
	c0.9,0,1.6,0,2.1,0.4c0.5,0.5,0.4,1.5,0.4,2.5c0,1.8,0,3.6,0,5.5c0,1,0,2-0.4,2.5c-0.2,0.2-0.6,0.3-1,0.4c-0.2,0-0.4,0-0.6,0
	c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0-0.5,0.1c-0.5,0.2-0.8,0.6-1,1.1c-0.1,0.2,0,0.5,0,0.7c0,0.9,0.3,1.5,1,1.7c0.4,0.1,1,0,1.4,0.2
	c0.4,0.1,0.8,0.3,1,0.6c0.4,0.6,0.3,1.7,0.3,2.6c0,1.5,0,2.9,0,4.4c0,0.5,0,0.9,0,1.4c0,0.2,0,0.5,0,0.7c0,0.2-0.1,0.4-0.1,0.5
	c-0.1,0.4-0.4,0.7-0.8,0.9c-0.5,0.2-1.1,0.1-1.7,0.2c-0.6,0.1-0.9,0.4-1.1,1c-0.2,0.7-0.1,1.9,0.3,2.3c0.2,0.2,0.5,0.4,0.8,0.4
	c0.3,0.1,0.6,0,1,0.1c0.5,0.1,1,0.2,1.2,0.5c0.4,0.6,0.4,1.7,0.4,2.7c0,1.8,0,3.5,0,5.3c0,1.3,0,2.4-0.9,2.8
	c-0.6,0.3-1.9,0.2-2.7,0.2c-1.1,0-2.7,0.1-3.3-0.3c-0.2-0.2-0.4-0.4-0.5-0.7c0-0.1,0-0.3-0.1-0.4c0-0.2-0.1-0.6,0-0.8
	c0.1-0.5,0.2-1,0.5-1.2c0.7-0.5,1.9-0.2,2.6-0.6c0.2-0.1,0.4-0.4,0.5-0.6c0.2-0.5,0.2-1.6,0-2.1c-0.2-0.5-0.7-0.7-1.3-0.8
	c-0.1,0-0.3,0-0.4,0c-0.2,0-0.4,0-0.6,0c-0.4,0-0.8,0-1.2,0c-1.5,0-3,0-4.4,0c-0.9,0-2.1-0.1-2.6,0.2c-0.4,0.3-0.6,0.6-0.7,1.2
	c0,0.2,0,0.6,0,0.8s0,0.4,0.1,0.5c0.2,0.5,0.6,0.8,1.1,1c0.5,0.1,1.2,0,1.7,0.2c0.4,0.2,0.7,0.5,0.8,0.9c0,0.2,0.1,0.4,0.1,0.5
	c0,0.2,0,0.6,0,0.8c0,0.1,0,0.3-0.1,0.4c-0.1,0.3-0.3,0.6-0.6,0.8c-0.7,0.4-2.4,0.3-3.5,0.3c-1.1,0-2.4,0.1-3-0.4
	c-0.5-0.5-0.4-1.5-0.4-2.5c0-1.8,0-3.5,0-5.3c0-0.9-0.1-2,0.3-2.5c0.2-0.4,0.6-0.5,1.1-0.6c0.5-0.1,1,0,1.4-0.2
	c0.5-0.2,0.8-0.5,0.9-0.9c0.1-0.2,0.1-0.5,0.1-0.8c0-1-0.3-1.6-1-1.9c-0.4-0.1-1,0-1.4-0.2c-0.4-0.1-0.8-0.3-1-0.6
	c-0.4-0.6-0.3-1.7-0.3-2.6c0-1.5,0-3.1,0-4.6c0-0.7-0.1-1.8,0.1-2.4c0.1-0.5,0.4-0.9,1-1.1c0.6-0.2,1.4-0.1,1.9-0.3
	c0.4-0.2,0.6-0.5,0.8-0.9c0.1-0.2,0.1-0.4,0.1-0.5c0-0.2,0-0.5,0-0.6c0-0.2,0-0.4-0.1-0.5c-0.2-0.5-0.6-0.8-1.1-1
	c-0.3-0.1-0.6,0-1-0.1c-0.5-0.1-1-0.2-1.2-0.5c-0.4-0.5-0.4-1.5-0.4-2.5c0-1.7,0-3.4,0-5.1c0-0.9-0.1-2,0.3-2.5
	c0.2-0.3,0.6-0.5,1-0.6C35.7,34.3,36.1,34.3,36.2,34.3z M59,34.3c2,0,4.1,0,6.2,0c1,0,2.2-0.1,2.8,0.4c0.5,0.4,0.7,1.6,0.4,2.4
	c-0.4,1.2-1.7,1.1-3.3,1.1c-1.7,0-3.3,0-5,0c-0.9,0-1.9,0.1-2.4-0.3c-0.2-0.1-0.4-0.4-0.5-0.6c-0.3-0.6-0.2-1.8,0.1-2.2
	c0.2-0.3,0.6-0.5,1-0.6C58.6,34.3,58.9,34.4,59,34.3z M39.9,38.1c-0.2,0-0.4,0-0.6,0.1c-0.4,0.1-0.8,0.3-0.9,0.6
	C38,39.2,38,40.4,38.3,41c0.1,0.2,0.3,0.4,0.5,0.6c0.5,0.4,1.5,0.3,2.4,0.3c1.6,0,3.3,0,5,0c1.6,0,2.9,0.2,3.3-1.1
	c0.3-0.8,0.1-2.1-0.4-2.4C48.3,38,47.2,38,46.1,38C44,38.1,42,38.1,39.9,38.1z M43.5,45.7c-0.1,0-0.4,0.1-0.5,0.1
	c-0.4,0.1-0.7,0.4-0.9,0.8c-0.2,0.4-0.2,1.1-0.2,1.7c0,0.8,0,1.7,0,2.5c0,0.6,0,1.2-0.2,1.6c-0.2,0.4-0.5,0.7-0.9,0.8
	c-0.6,0.2-1.4,0.1-1.9,0.3c-0.7,0.3-0.8,0.9-0.8,1.9c0,0.3,0,0.5,0.1,0.8c0.2,0.5,0.5,0.8,0.9,0.9c0.4,0.1,1,0,1.4,0.2
	c0.6,0.1,1,0.4,1.2,0.8c0.2,0.4,0.2,1,0.2,1.6c0,0.8,0,1.7,0,2.5c0,0.6,0,1.2,0.2,1.7c0.2,0.5,0.6,0.7,1.2,0.8c0.2,0,0.7,0.1,0.9,0
	c0.2,0,0.4,0,0.5-0.1c0.4-0.1,0.7-0.4,0.9-0.8c0.2-0.4,0.2-1.1,0.2-1.7c0-0.8,0-1.7,0-2.5c0-0.6,0-1.2,0.2-1.6
	c0.2-0.5,0.6-0.7,1.2-0.8c0.5-0.1,1,0,1.4-0.2c0.7-0.2,1-0.8,1-1.8c0-0.3,0-0.5,0-0.7c-0.1-0.6-0.4-0.9-1-1.1c-0.4-0.1-1,0-1.4-0.2
	c-0.6-0.1-1-0.4-1.2-0.8c-0.2-0.4-0.2-1-0.2-1.6c0-0.9,0-1.7,0-2.6c0-0.7,0-1.4-0.3-1.8C45.1,45.8,44.4,45.7,43.5,45.7z M36.2,83.8
	c3.3,0,6.6,0,9.9,0c1,0,2.1-0.1,2.8,0.3c0.4,0.2,0.5,0.5,0.6,1c0,0.2,0.1,0.6,0.1,0.9c0,0.1,0,0.3-0.1,0.4c-0.1,0.4-0.3,0.7-0.6,0.9
	c-0.6,0.4-1.6,0.3-2.4,0.3c-1.7,0-3.4,0-5.1,0c-0.7,0-1.7-0.1-2.2,0.1c-0.4,0.1-0.7,0.4-0.8,0.7c-0.3,0.6-0.2,1.8,0.1,2.3
	c0.5,0.8,1.9,0.7,3.2,0.7c2.7,0,5.4,0,8.1,0c1.4,0,2.8,0.2,3.4-0.7c0.3-0.4,0.3-1.1,0.3-1.8c0-0.9,0-1.7,0-2.6c0-0.6,0-1.2,0.2-1.6
	c0.2-0.5,0.6-0.7,1.2-0.8c0.2,0,0.6-0.1,0.8,0c0.1,0,0.2,0,0.3,0c0.4,0.1,0.8,0.3,1,0.6c0.3,0.4,0.3,1.1,0.3,1.8c0,0.8,0,1.7,0,2.5
	c0,1,0,1.9,0.5,2.3c0.7,0.5,2.2,0.4,3.4,0.4c2.6,0,5.3,0,7.9,0c1.6,0,2.8,0.1,3.2-1.1c0.3-0.8,0.1-2.1-0.4-2.4
	c-0.6-0.4-1.6-0.4-2.6-0.4c-1.7,0-3.5,0-5.2,0c-0.9,0-1.8,0.1-2.4-0.3c-0.5-0.3-0.7-1-0.7-1.8c0-0.2,0-0.5,0.1-0.6
	c0.4-1.2,1.7-1.1,3.3-1.1c2.7,0,5.5,0,8.2,0c1.5,0,3-0.2,3.5,0.9c0.2,0.4,0.2,1,0.2,1.6c0,0.8,0,1.5,0,2.3c0,1.3,0,2.6-0.1,3.8
	c0,1,0,1.9-0.4,2.4c-0.6,0.6-1.9,0.4-3,0.4c-2.5,0-5,0-7.5,0c-1.7,0-3.3,0-5,0c-1,0-2,0-2.5,0.4c-0.3,0.2-0.4,0.5-0.5,0.9
	c0,0.1,0,0.2,0,0.4c0,0.1,0,0.5,0,0.6c0.1,0.6,0.2,1,0.6,1.3c0.4,0.3,1.1,0.3,1.8,0.3c0.8,0,1.6,0,2.4,0c3.2,0,6.3,0,9.5,0
	c0.6,0,1.2,0,1.9,0c0.3,0,0.7,0,0.9,0c0.1,0,0.2,0,0.3,0c0.3,0.1,0.6,0.1,0.8,0.2c0.3,0.2,0.5,0.4,0.6,0.8c0.1,0.2,0.1,0.6,0,0.9
	c0,0.1,0,0.2,0,0.4c-0.1,0.4-0.1,0.8-0.2,1.2c-0.1,0.3-0.1,0.5-0.1,0.8c-0.2,0.7-0.3,1.4-0.6,2c-0.2,0.4-0.3,0.9-0.5,1.4
	c-0.5,1.1-1.1,2.2-1.7,3.2c-0.4,0.6-1,1.2-1.3,1.9c-0.1,0.2-0.3,0.6-0.2,0.9c0.2,0.7,0.6,1.1,0.9,1.6c0.4,0.5,0.8,1.1,1.1,1.6
	c0.8,1.2,1.4,2.6,1.9,4.1c0.1,0.4,0.2,0.8,0.3,1.3c0.1,0.5,0.2,1.1,0.3,1.6c0,0.2,0,0.5,0.1,0.7c0.1,0.4,0.1,1.1-0.1,1.4
	c-0.4,0.9-1.4,0.8-2.7,0.8c-1.9,0-3.8,0-5.7,0c-7.4,0-14.8,0-22.2,0c-1.5,0-2.9,0-4.4,0c-0.8,0-1.7,0-2.2,0.3
	c-0.4,0.3-0.5,0.7-0.6,1.3c0,0.2,0,0.5,0,0.6c0,0.1,0,0.2,0,0.4c0.1,0.3,0.2,0.7,0.5,0.9c0.6,0.4,1.6,0.4,2.6,0.4c1.7,0,3.5,0,5.2,0
	c7.4,0,14.8,0,22.2,0c1.6,0,3.2,0,4.9,0c1.1,0,2.1,0,2.5,0.7c0.4,0.6,0.3,1.6,0.3,2.5c0,1.8,0,3.5,0,5.3c0,1.1,0,2.1-0.6,2.5
	c-0.5,0.4-1.5,0.4-2.5,0.4c-1.7,0-3.4,0-5,0c-6.7,0-13.4,0-20.1,0c-2.1,0-4.3,0-6.4,0c-1.1,0-2.3-0.1-2.9,0.4
	c-0.3,0.2-0.4,0.5-0.5,0.9c0,0.1,0,0.2,0,0.4c0,0.1,0,0.5,0,0.6c0.1,0.6,0.2,1,0.6,1.3c0.5,0.3,1.4,0.3,2.3,0.3c1.6,0,3.1,0,4.7,0
	c7.8,0,15.7,0,23.6,0c1.4,0,2.8,0,4.2,0c0.9,0,1.7,0,2.2,0.4c0.8,0.6,0.7,2.7-0.1,3.2c-0.6,0.4-1.8,0.3-2.7,0.3c-2,0-4,0-6,0
	c-7.6,0-15.1,0-22.7,0c-2.2,0-4.4,0-6.5,0c-1.1,0-2.3,0.1-2.8-0.4c-0.5-0.5-0.4-1.6-0.4-2.6c0-1.6,0-3.1,0-4.6c0-0.4,0-0.8,0-1.2
	c0-0.2,0-0.4,0-0.6c0-0.1,0-0.3,0-0.4c0.1-0.5,0.3-0.9,0.6-1.2c0.5-0.3,1.4-0.3,2.2-0.3c1.4,0,2.9,0,4.3,0c7.5,0,14.9,0,22.4,0
	c1.8,0,3.5,0,5.3,0c0.9,0,1.9,0.1,2.5-0.3c0.4-0.3,0.5-0.6,0.6-1.2c0-0.2,0-0.6,0-0.8c0-0.1,0-0.2,0-0.4c-0.1-0.3-0.2-0.5-0.3-0.7
	c-0.5-0.7-1.9-0.5-3.1-0.5c-1.6,0-3.2,0-4.7,0c-7.5,0-15,0-22.5,0c-1.3,0-2.7,0-4,0c-0.9,0-2,0.1-2.6-0.3c-0.3-0.2-0.5-0.5-0.6-0.9
	c0-0.1,0-0.3-0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4,0-0.6c0-0.4,0-0.8,0-1.2c0-1.6,0-3.2,0-4.8c0-1.1,0-2.1,0.6-2.6
	c0.5-0.4,1.4-0.4,2.3-0.4c1.5,0,2.9,0,4.4,0c5.9,0,11.9,0,17.8,0c3.1,0,6.2,0,9.2,0c1,0,2.1,0.1,2.5-0.5c0.1-0.2,0.3-0.5,0.2-0.8
	c-0.4-1.6-1.2-2.9-2.1-4c-0.4-0.5-0.8-1.1-1.5-1.3c-0.2-0.1-0.6,0-0.8,0c-0.5,0.2-0.9,0.4-1.3,0.6c-0.9,0.5-1.9,0.8-2.8,1.2
	c-0.5,0.2-0.9,0.2-1.4,0.4c-0.3,0.1-0.6,0.1-0.9,0.2c-0.3,0.1-0.6,0.1-0.9,0.1c-0.3,0.1-0.6,0-0.9,0.1c-0.1,0-0.1,0-0.2,0
	c-0.2,0-0.4,0-0.5,0c-0.1,0-0.2,0-0.3,0c-0.2,0-0.5,0-0.7,0c-0.2,0-0.4,0-0.6,0c-0.4,0-0.8,0-1.2,0c-2.8,0-5.5,0-8.2,0c-3,0-6,0-9,0
	c-0.9,0-2.1,0.1-2.7-0.2c-0.4-0.2-0.7-0.5-0.8-1c0-0.2-0.1-0.4-0.1-0.6c0-0.8,0.2-1.4,0.7-1.7c0.4-0.3,1.1-0.3,1.8-0.3
	c0.9,0,1.9,0,2.8,0c3.8,0,7.6,0,11.5,0c1.3,0,2.5,0,3.8,0c0.7,0,1.5,0,2.2,0c0.4,0,0.8,0,1.1,0c0.1,0,0.1,0,0.2,0
	c0.5-0.1,1-0.1,1.5-0.2c0.8-0.2,1.7-0.4,2.4-0.7c0.4-0.2,0.8-0.3,0.9-0.7c0.1-0.2,0.1-0.4,0-0.6c-0.3-0.4-0.7-0.6-1.2-0.8
	c-0.6-0.3-1.4-0.4-2.1-0.6c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.1-0.5,0-0.8-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.4,0-0.5,0
	c-0.3-0.1-0.9,0-1.2,0c-1.2,0-2.4,0-3.5,0c-3.7,0-7.5,0-11.2,0c-1.2,0-2.4,0-3.6,0c-1.1,0-2.2,0.1-2.7-0.4c-0.2-0.2-0.3-0.5-0.4-0.8
	c0-0.2-0.1-0.6-0.1-0.9c0.1-0.6,0.2-1,0.6-1.3c0.7-0.4,2.5-0.3,3.6-0.3c2.9,0,5.7,0,8.6,0c2.7,0,5.3,0,8,0c0.4,0,0.8,0,1.2,0
	c0.2,0,0.4,0,0.6,0c0.2,0,0.5,0,0.7,0c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0,0.5,0c0.1,0,0.1,0,0.2,0c0.3,0.1,0.6,0,0.9,0.1
	c0.3,0.1,0.6,0.1,0.9,0.1c0.4,0.1,0.7,0.1,1.1,0.2c0.4,0.1,0.9,0.2,1.3,0.4c1,0.3,2,0.7,2.8,1.2c0.4,0.2,0.8,0.4,1.3,0.6
	c0.2,0.1,0.5,0.1,0.8,0c0.6-0.2,1-0.6,1.4-1.1c0.6-0.8,1.2-1.7,1.6-2.7c0.2-0.4,0.4-0.8,0.5-1.2c0.2-0.6-0.1-1.1-0.5-1.3
	c-0.5-0.3-1.6-0.2-2.4-0.2c-1.8,0-3.6,0-5.3,0c-6.5,0-13.1,0-19.6,0c-1.9,0-3.7,0-5.6,0c-0.9,0-2.1,0.1-2.7-0.2
	c-0.4-0.2-0.7-0.5-0.8-1c0-0.1,0-0.3,0-0.4c0-0.2,0-0.5,0-0.7c0-0.2,0-0.4,0.1-0.5c0.2-0.4,0.5-0.7,0.9-0.8c0.4-0.1,1-0.1,1.6-0.1
	c0.9,0,1.7,0,2.6,0c3,0,5.9,0,8.9,0c0.8,0,1.6,0,2.5,0c0.7,0,1.4,0,1.8-0.3c0.4-0.3,0.5-0.7,0.6-1.3c0-0.2,0-0.7,0-0.9
	c-0.1-0.5-0.3-0.8-0.7-1.1c-0.2-0.1-0.5-0.2-0.8-0.2c-0.1,0-0.2,0-0.2,0c-0.2,0-0.6,0-0.8,0c-0.6,0-1.1,0-1.7,0c-3.1,0-6.2,0-9.3,0
	c-0.9,0-1.8,0-2.7,0c-0.6,0-1.2,0-1.6-0.1c-1.1-0.4-1.1-1.5-1.1-2.9c0-1.2,0-2.5,0-3.7c0-0.7,0-1.4,0-2.1c0-0.5,0-1,0.1-1.4
	c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.2,0.5-0.3,0.9-0.4C35.8,83.9,36.1,83.9,36.2,83.8z M108.5,83.8c10.1,0,20.1,0,30.2,0
	c2.2,0,4.5,0,6.7,0c1.4,0,2.5-0.1,2.9,0.9c0.1,0.2,0.1,0.5,0.1,0.7c0,0.2,0,0.5,0,0.7c0,0.1,0,0.3-0.1,0.4c-0.1,0.3-0.3,0.6-0.6,0.8
	c-0.7,0.4-2.4,0.3-3.6,0.3c-3.1,0-6.2,0-9.2,0c-0.9,0-1.9,0-2.8,0c-0.5,0-1.1,0-1.6,0.1c-0.5,0.1-0.9,0.4-1.1,0.8
	c-0.3,0.7-0.2,2.1-0.2,3.1c0,3.4,0,6.8,0,10.2c0,0.8,0,1.7,0,2.5c0,0.6,0,1.2,0.2,1.6c0.1,0.2,0.4,0.4,0.6,0.6
	c0.4,0.2,1.1,0.2,1.7,0.2c0.6,0,1.1,0,1.7,0c2.4,0,4.8,0,7.2,0c1.2,0,2.5,0.1,3.2-0.4c0.3-0.2,0.4-0.5,0.5-0.9c0-0.1,0-0.2,0-0.4
	c0-0.1,0-0.5,0-0.6c-0.1-0.6-0.2-1-0.6-1.3c-0.5-0.4-1.5-0.3-2.4-0.3c-1.6,0-3.3,0-4.9,0c-1,0-2.2,0.1-2.8-0.3
	c-0.4-0.2-0.5-0.6-0.6-1.1c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.6c0-0.4,0-0.8,0-1.2c0-1.6,0-3.2,0-4.7c0-1.1,0-2.1,0.6-2.6
	c0.7-0.5,2.1-0.4,3.3-0.4c2.5,0,5.1,0,7.6,0c1.2,0,2.8-0.2,3.4,0.4c0.4,0.4,0.6,1.5,0.3,2.3c-0.1,0.4-0.4,0.7-0.8,0.9
	c-0.6,0.2-1.5,0.2-2.3,0.2c-1.7,0-3.4,0-5.2,0c-0.9,0-1.9-0.1-2.4,0.3c-0.4,0.3-0.5,0.7-0.6,1.3c0,0.2,0,0.7,0,0.9
	c0.1,0.4,0.2,0.8,0.5,1c0.6,0.4,1.5,0.4,2.5,0.4c1.6,0,3.3,0,4.9,0c0.8,0,1.9-0.1,2.5,0.1c1.1,0.4,1.1,1.5,1.1,2.9
	c0,1.3,0,2.5,0,3.8c0,0.7,0,1.4,0,2.1c0,0.5,0,1-0.1,1.4c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.1-0.4,0.3-0.6,0.3
	c-0.4,0.1-0.9,0.1-1.4,0.1c-0.7,0-1.3,0-2,0c-3.4,0-6.9,0-10.3,0c-0.6,0-1.1,0-1.7,0c-0.3,0-0.6,0-0.9,0c-0.1,0-0.2,0-0.2,0
	c-0.3,0.1-0.6,0.1-0.8,0.2c-0.4,0.3-0.5,0.5-0.7,1.1c0,0.2-0.1,0.7,0,0.9c0.1,0.5,0.2,1,0.5,1.2c0.2,0.1,0.4,0.2,0.7,0.3
	c0.4,0.1,0.8,0.1,1.2,0.1c0.6,0,1.3,0,1.9,0c3.4,0,6.8,0,10.1,0c0.6,0,1.2,0,1.8,0c0.3,0,0.7,0,0.9,0c0.1,0,0.2,0,0.3,0
	c0.4,0.1,0.7,0.1,0.9,0.3c0.5,0.3,0.7,0.9,0.7,1.8c0,0.2,0,0.5-0.1,0.7c-0.1,0.4-0.4,0.7-0.8,0.9c-0.4,0.2-1,0.2-1.5,0.2
	c-0.7,0-1.4,0-2.1,0c-3.5,0-6.9,0-10.4,0c-1.3,0-3.2-0.2-3.8,0.5c-0.5,0.7-0.4,2-0.4,3.2c0,2.4,0,4.8,0,7.2c0,3.8,0,7.6,0,11.4
	c0,0.7,0,1.3,0,2c0,0.4,0,0.9,0.1,1.2c0.1,0.3,0.2,0.7,0.5,0.9c0.6,0.4,1.6,0.4,2.7,0.4c1.9,0,3.7,0,5.6,0c1.2,0,2.5,0,3.7,0
	c0.8,0,1.6,0,2-0.3c0.5-0.3,0.6-0.9,0.6-1.7c0-0.2,0-0.4,0-0.6c-0.1-0.5-0.3-0.8-0.7-1c-0.5-0.3-1.6-0.2-2.4-0.2c-1.7,0-3.4,0-5,0
	c-0.9,0-2,0.1-2.6-0.3c-0.3-0.2-0.5-0.5-0.6-0.9c0-0.1,0-0.3-0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.2,0-0.5,0-0.7c0-0.4,0-0.9,0-1.3
	c0-1.5,0-3,0-4.5c0-0.8,0-1.7,0.3-2.3c0.2-0.4,0.6-0.5,1.1-0.6c0.2,0,0.3,0,0.5,0c0.2,0,0.4,0,0.6,0c0.4,0,0.7,0,1.1,0
	c1.4,0,2.9,0,4.3,0c1.1,0,2.4,0.1,3-0.4c0.4-0.3,0.4-0.7,0.5-1.2c0-0.2,0-0.7,0-0.9c-0.1-0.3-0.1-0.6-0.3-0.8
	c-0.4-0.6-1.6-0.5-2.6-0.5c-1.7,0-3.4,0-5,0c-0.8,0-1.9,0.1-2.5-0.2c-0.4-0.2-0.7-0.5-0.8-0.9c-0.1-0.2,0-0.4-0.1-0.6
	c0-0.1,0-0.4,0-0.5c0-0.2,0-0.3,0-0.4c0.1-0.5,0.4-0.8,0.8-1c0.2-0.1,0.5-0.1,0.7-0.1c0.1,0,0.2,0,0.3,0c0.2,0,0.5,0,0.8,0
	c0.5,0,1,0,1.6,0c2.4,0,4.8,0,7.2,0c1.2,0,2.8-0.2,3.4,0.4c0.5,0.5,0.4,1.6,0.4,2.6c0,1.7,0,3.3,0,5c0,0.8,0.1,1.9-0.2,2.5
	c-0.4,1.1-1.8,0.9-3.3,0.9c-1.7,0-3.3,0-5,0c-0.9,0-1.9-0.1-2.4,0.3c-0.4,0.3-0.5,0.7-0.6,1.3c0,0.2,0,0.5,0,0.6c0,0.1,0,0.2,0,0.4
	c0.1,0.3,0.2,0.7,0.5,0.9c0.6,0.4,1.6,0.4,2.6,0.4c1.8,0,3.5,0,5.3,0c1,0,2.1-0.1,2.6,0.4c0.5,0.5,0.4,1.6,0.4,2.6
	c0,1.8,0,3.7,0,5.5c0,1.1,0,2.1-0.7,2.6c-0.6,0.4-1.8,0.3-2.8,0.3c-2,0-4,0-6,0c-7.6,0-15.3,0-22.9,0c-2.1,0-4.3,0-6.4,0
	c-1.4,0-2.5,0.1-2.9-0.9c-0.3-0.6-0.2-2-0.2-2.9c0-1.6,0-3.1,0-4.6c0-0.8,0-1.8,0.3-2.3c0.2-0.4,0.6-0.5,1.1-0.6c0.2,0,0.3,0,0.5,0
	c0.2,0,0.4,0,0.6,0c0.4,0,0.8,0,1.2,0c1.6,0,3.2,0,4.7,0c1.1,0,2.2,0.1,2.6-0.5c0.2-0.2,0.2-0.4,0.3-0.8c0-0.2,0.1-0.7,0-0.9
	c-0.1-0.5-0.2-1-0.5-1.2c-0.7-0.5-1.9-0.4-3-0.4c-1.5,0-3,0-4.5,0c-0.8,0-1.7,0.1-2.3-0.1c-1.1-0.4-1.1-1.5-1.1-2.9
	c0-1.3,0-2.5,0-3.8c0-0.7,0-1.4,0-2.1c0-0.5,0-1,0.1-1.4c0.1-0.2,0.2-0.4,0.3-0.6c0.4-0.4,1.2-0.4,2.1-0.4c0.8,0,1.5,0,2.3,0
	c2.3,0,4.7,0,7,0c1.1,0,2.4-0.1,2.9,0.4c0.4,0.4,0.6,1.4,0.4,2.1c-0.1,0.4-0.3,0.7-0.6,0.9c-0.6,0.4-1.6,0.3-2.5,0.3
	c-1.6,0-3.2,0-4.8,0c-0.9,0-1.9-0.1-2.5,0.2c-0.4,0.2-0.7,0.6-0.8,1.1c0,0.2,0,0.4,0,0.7c0,0.9,0.2,1.5,0.8,1.8
	c0.6,0.2,1.5,0.2,2.3,0.2c1.7,0,3.4,0,5.1,0c0.9,0,1.9-0.1,2.4,0.3c0.4,0.2,0.5,0.6,0.6,1.1c0,0.2,0,0.3,0,0.5c0,0.2,0,0.4,0,0.6
	c0,0.4,0,0.9,0,1.3c0,1.4,0,2.9,0,4.3c0,0.9,0.1,2-0.3,2.6c-0.2,0.3-0.5,0.5-0.9,0.6c-0.6,0.2-1.6,0.1-2.3,0.1c-1.5,0-2.9,0-4.4,0
	c-1.1,0-2.4-0.1-3,0.4c-0.4,0.3-0.4,0.7-0.5,1.2c0,0.2,0,0.5,0,0.6c0,0.1,0,0.2,0,0.4c0.1,0.5,0.3,0.8,0.7,1
	c0.6,0.4,2.3,0.2,3.3,0.2c2.5,0,4.9,0,7.4,0c1.2,0,2.6,0.1,3.2-0.4c0.5-0.4,0.5-1.2,0.5-2.1c0-1.2,0-2.4,0-3.6c0-5,0-10,0-15.1
	c0-0.9,0-1.9,0-2.8c0-1.1,0-2-0.7-2.4c-0.4-0.3-1.1-0.3-1.8-0.3c-0.8,0-1.6,0-2.3,0c-3.3,0-6.7,0-10,0c-0.5,0-1.1,0-1.6,0
	c-0.3,0-0.6,0-0.8,0c-0.1,0-0.2,0-0.3,0c-0.2,0-0.5-0.1-0.7-0.1c-0.6-0.3-1-1-0.9-2c0-0.3,0.1-0.5,0.1-0.7c0.1-0.3,0.4-0.6,0.7-0.7
	c0.4-0.2,1-0.2,1.6-0.2c0.8,0,1.5,0,2.3,0c3.3,0,6.6,0,10,0c0.6,0,1.3,0,1.9,0c0.4,0,0.8,0,1.2-0.1c0.3-0.1,0.5-0.2,0.7-0.3
	c0.4-0.3,0.4-0.7,0.5-1.2c0-0.2,0-0.7,0-0.9c-0.1-0.5-0.3-0.8-0.7-1.1c-0.2-0.1-0.5-0.2-0.8-0.2c-0.1,0-0.2,0-0.2,0
	c-0.2,0-0.6,0-0.8,0c-0.6,0-1.1,0-1.7,0c-3.4,0-6.8,0-10.3,0c-0.7,0-1.3,0-2,0c-0.5,0-1,0-1.4-0.1c-0.2-0.1-0.4-0.2-0.6-0.3
	c-0.1-0.1-0.3-0.4-0.3-0.6c-0.1-0.4-0.1-0.9-0.1-1.4c0-0.7,0-1.4,0-2.1c0-1.3,0-2.5,0-3.8c0-1.5-0.1-2.6,1.1-2.9
	c0.6-0.2,1.7-0.1,2.5-0.1c1.6,0,3.2,0,4.8,0c1,0,1.9,0,2.5-0.4c0.3-0.2,0.4-0.6,0.5-1c0-0.2,0.1-0.7,0-0.9c-0.1-0.6-0.2-1-0.6-1.3
	c-0.5-0.4-1.5-0.3-2.4-0.3c-1.7,0-3.4,0-5.1,0c-0.8,0-1.7,0.1-2.3-0.2c-0.4-0.2-0.7-0.5-0.8-0.9c-0.3-0.8,0-2,0.4-2.4
	c0.7-0.5,2.1-0.4,3.2-0.4c2.5,0,5.1,0,7.6,0c1.2,0,2.7-0.2,3.4,0.4c0.5,0.5,0.4,1.6,0.4,2.6c0,1.6,0,3.1,0,4.7
	c0,0.7,0.1,1.8-0.1,2.4c-0.1,0.5-0.4,0.8-0.8,1c-0.6,0.3-1.7,0.2-2.5,0.2c-1.6,0-3.3,0-4.9,0c-0.9,0-1.8-0.1-2.4,0.3
	c-0.4,0.3-0.5,0.7-0.6,1.3c0,0.2,0,0.5,0,0.6c0,0.1,0,0.2,0,0.4c0.1,0.3,0.2,0.7,0.5,0.9c0.7,0.5,2,0.4,3.2,0.4c2.4,0,4.7,0,7.1,0
	c0.7,0,1.3,0,2,0c0.4,0,0.9,0,1.2-0.1c0.3-0.1,0.7-0.2,0.9-0.5c0.4-0.5,0.4-1.4,0.4-2.4c0-1.5,0-3,0-4.5c0-2.7,0-5.4,0-8.2
	c0-1.3,0.1-2.7-0.7-3.2c-0.4-0.3-1.1-0.3-1.8-0.3c-0.8,0-1.6,0-2.3,0c-3.2,0-6.4,0-9.6,0c-0.6,0-1.2,0-1.9,0c-0.3,0-0.7,0-0.9,0
	c-0.1,0-0.2,0-0.3,0c-0.4-0.1-0.7-0.1-0.9-0.3c-0.8-0.5-0.9-2.5-0.1-3.2c0.2-0.2,0.5-0.2,0.8-0.3C108.1,83.9,108.4,83.9,108.5,83.8z
	 M13.1,83.9c0.7,0,1.3,0.1,1.7,0.4c0.6,0.6,0.4,2,0.4,3.1c0,2.6,0,5.1,0,7.7c0,1.1-0.1,2.7,0.3,3.3c0.3,0.4,0.6,0.5,1.2,0.6
	c0.2,0,0.6,0,0.8,0c0.1,0,0.2,0,0.4,0c0.3-0.1,0.5-0.2,0.7-0.3c0.7-0.5,0.5-2.1,0.5-3.3c0-2.7,0-5.5,0-8.2c0-1.5-0.1-2.8,0.9-3.2
	c0.2-0.1,0.5-0.1,0.7-0.1c0.3-0.1,0.7,0,0.9,0.1c0.4,0.1,0.8,0.3,1,0.6c0.4,0.6,0.3,2.1,0.3,3.1c0,2.5,0,5,0,7.6
	c0,1.1-0.1,2.6,0.3,3.2c0.3,0.4,0.7,0.5,1.3,0.6c0.2,0,0.5,0,0.6,0c0.1,0,0.2,0,0.4,0c0.4-0.1,0.8-0.3,0.9-0.6
	c0.4-0.7,0.3-2.2,0.3-3.3c0-2.6,0-5.2,0-7.8c0-1-0.1-2.2,0.3-2.8c0.2-0.4,0.5-0.5,1-0.6c0.2,0,0.6-0.1,0.9-0.1
	c0.2,0,0.5,0.1,0.7,0.1c0.3,0.1,0.6,0.4,0.7,0.7c0.1,0.2,0.1,0.5,0.1,0.7c0,0.1,0,0.2,0,0.3c0,0.2,0,0.5,0,0.8c0,0.5,0,1,0,1.6
	c0,3.5,0,6.9,0,10.4c0,0.7,0,1.3,0,2c0,0.5,0,1-0.1,1.4c-0.1,0.2-0.2,0.4-0.3,0.6c-0.4,0.4-1.3,0.4-2.2,0.4c-0.8,0-1.6,0-2.4,0
	c-0.6,0-1.2,0-1.7,0.2c-0.4,0.2-0.7,0.6-0.8,1.1c-0.1,0.2-0.1,0.7,0,1c0,0.2,0,0.4,0.1,0.5c0.2,0.5,0.6,0.8,1.1,1
	c0.4,0.1,0.9,0.1,1.3,0.1c0.8,0,1.5,0,2.2,0c1.3,0,2.3,0,2.6,0.9c0.3,0.6,0.2,2.1,0.2,3c0,2.1,0,4.2,0,6.4c0,7.4,0,14.8,0,22.1
	c0,2.1,0,4.3,0,6.4c0,1.5,0.1,2.6-1.1,3c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.5,0-0.7,0c-0.4-0.1-0.7-0.1-1-0.4
	c-0.5-0.5-0.4-1.7-0.4-2.8c0-1.2,0-2.4,0-3.5c0-7.7,0-15.4,0-23.1c0-1.7,0-3.4,0-5.1c0-1,0-2-0.4-2.6c-0.2-0.3-0.5-0.4-0.9-0.5
	c-0.1,0-0.2,0-0.4,0c-0.1,0-0.5,0-0.6,0c-0.6,0.1-1,0.2-1.3,0.6c-0.4,0.6-0.3,1.6-0.3,2.5c0,1.8,0,3.6,0,5.3c0,7.5,0,14.9,0,22.4
	c0,1.5,0,2.9,0,4.4c0,0.8,0,1.7-0.3,2.2c-0.3,0.5-0.7,0.5-1.3,0.7c-0.1,0-0.4,0-0.5,0c-0.1,0-0.2,0-0.3,0c-0.4-0.1-0.7-0.2-0.9-0.4
	c-0.5-0.5-0.4-1.5-0.4-2.4c0-1.7,0-3.5,0-5.2c0-7.9,0-15.8,0-23.8c0-1.2,0-2.4,0-3.6c0-0.9,0-1.7-0.4-2.2c-0.4-0.5-1.2-0.6-2-0.5
	c-0.4,0-0.7,0.2-0.9,0.3c-0.6,0.4-0.5,1.3-0.5,2.3c0,1.7,0,3.3,0,5c0,7.3,0,14.5,0,21.8c0,1.8,0,3.6,0,5.5c0,0.9,0.1,2-0.3,2.5
	c-0.3,0.4-0.7,0.5-1.3,0.6c-0.3,0.1-0.7,0-0.9-0.1c-0.4-0.1-0.8-0.3-1-0.6c-0.3-0.4-0.3-1.2-0.3-1.9c0-1,0-2,0-2.9
	c0-6.2,0-12.4,0-18.6c0-4.1,0-8.1,0-12.2c0-1,0-2,0-3.1c0-0.7,0-1.4,0.3-1.9c0.4-0.6,1.2-0.7,2.2-0.7c0.8,0,1.7,0,2.5,0
	c0.5,0,1.1,0,1.6-0.1c0.5-0.2,0.8-0.6,1-1.1c0-0.1,0-0.2,0-0.4c0-0.3,0-0.8,0-1c-0.1-0.5-0.4-0.9-0.8-1.1c-0.4-0.2-1.1-0.2-1.7-0.2
	c-0.8,0-1.7,0-2.5,0c-0.6,0-1.2,0-1.6-0.2c-0.4-0.2-0.7-0.5-0.8-1c-0.1-0.5-0.1-1.1-0.1-1.6c0-0.9,0-1.9,0-2.8c0-3,0-5.9,0-8.9
	c0-0.6,0-1.1,0-1.7c0-0.3,0-0.6,0-0.9c0-0.1,0-0.2,0-0.3c0.1-0.4,0.1-0.7,0.3-0.9c0.2-0.3,0.4-0.5,0.8-0.6
	C12.6,83.9,13,83.9,13.1,83.9z M85.4,83.9c0.7,0,1.3,0.1,1.7,0.4c0.6,0.6,0.4,2,0.4,3.1c0,2.6,0,5.1,0,7.7c0,1.1-0.1,2.7,0.3,3.3
	c0.3,0.4,0.6,0.5,1.2,0.6c0.2,0,0.6,0,0.8,0c0.1,0,0.2,0,0.4,0c0.3-0.1,0.5-0.2,0.7-0.3c0.7-0.5,0.5-2.1,0.5-3.3c0-2.7,0-5.5,0-8.2
	c0-1.5-0.1-2.8,0.9-3.2c0.2-0.1,0.4-0.1,0.7-0.1c0.8-0.2,1.6,0.2,1.9,0.6c0.4,0.6,0.3,2.2,0.3,3.3c0,2.6,0,5.2,0,7.7
	c0,1.1-0.1,2.4,0.4,3.1c0.2,0.3,0.6,0.4,1,0.5c0.2,0,0.7,0.1,0.9,0c0.6-0.1,1-0.2,1.3-0.6c0.4-0.6,0.3-2.2,0.3-3.2c0-2.5,0-5,0-7.6
	c0-1.2-0.2-2.7,0.4-3.3c0.3-0.3,0.6-0.3,1-0.4c0.2,0,0.5-0.1,0.7,0c0.3,0,0.5,0.1,0.7,0.1c0.3,0.1,0.6,0.4,0.7,0.7
	c0.1,0.2,0.1,0.5,0.1,0.7c0,0.1,0,0.2,0,0.3c0,0.2,0,0.5,0,0.8c0,0.5,0,1,0,1.6c0,3.5,0,6.9,0,10.4c0,0.7,0,1.3,0,2
	c0,0.5,0,1-0.1,1.4c-0.1,0.2-0.2,0.4-0.3,0.6c-0.4,0.4-1.3,0.4-2.2,0.4c-0.8,0-1.6,0-2.4,0c-0.6,0-1.2,0-1.7,0.2
	c-0.4,0.2-0.7,0.6-0.8,1.1c-0.1,0.2-0.1,0.7,0,1c0,0.2,0,0.4,0.1,0.5c0.2,0.5,0.6,0.8,1.1,1c0.4,0.1,0.9,0.1,1.3,0.1
	c0.8,0,1.5,0,2.2,0c1.3,0,2.3,0,2.6,0.9c0.3,0.6,0.2,2.1,0.2,3c0,2.1,0,4.2,0,6.4c0,7.4,0,14.8,0,22.1c0,2.1,0,4.3,0,6.4
	c0,1.5,0.1,2.6-1.1,3c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.5,0-0.7,0c-0.4-0.1-0.7-0.1-1-0.4c-0.5-0.5-0.4-1.7-0.4-2.8
	c0-1.2,0-2.4,0-3.5c0-7.7,0-15.4,0-23.1c0-1.7,0-3.4,0-5.1c0-1,0-2-0.4-2.6c-0.2-0.3-0.5-0.4-0.9-0.5c-0.1,0-0.2,0-0.4,0
	c-0.1,0-0.5,0-0.6,0c-0.6,0.1-1,0.2-1.3,0.6c-0.4,0.6-0.3,1.6-0.3,2.5c0,1.8,0,3.6,0,5.3c0,7.5,0,14.9,0,22.4c0,1.5,0,2.9,0,4.4
	c0,0.8,0,1.7-0.3,2.2c-0.3,0.5-0.7,0.5-1.3,0.7c-0.1,0-0.4,0-0.5,0c-0.1,0-0.2,0-0.3,0c-0.4-0.1-0.7-0.2-0.9-0.4
	c-0.5-0.5-0.4-1.5-0.4-2.4c0-1.7,0-3.5,0-5.2c0-7.9,0-15.8,0-23.8c0-1.2,0-2.4,0-3.6c0-0.9,0-1.7-0.4-2.2c-0.4-0.5-1.2-0.6-2-0.5
	c-0.4,0-0.7,0.2-0.9,0.3c-0.6,0.4-0.5,1.3-0.5,2.3c0,1.7,0,3.3,0,5c0,7.3,0,14.5,0,21.8c0,1.8,0,3.6,0,5.5c0,0.9,0.1,2-0.3,2.5
	c-0.3,0.4-0.7,0.5-1.3,0.6c-0.3,0.1-0.7,0-0.9-0.1c-0.4-0.1-0.8-0.3-1-0.6c-0.3-0.4-0.3-1.2-0.3-1.9c0-1,0-2,0-2.9
	c0-6.2,0-12.4,0-18.6c0-4.1,0-8.1,0-12.2c0-1,0-2,0-3.1c0-0.7,0-1.4,0.3-1.9c0.4-0.6,1.2-0.7,2.2-0.7c0.8,0,1.7,0,2.5,0
	c0.5,0,1.1,0,1.6-0.1c0.5-0.2,0.8-0.6,1-1.1c0-0.1,0-0.2,0-0.4c0-0.3,0-0.8,0-1c-0.1-0.5-0.4-0.9-0.8-1.1c-0.4-0.2-1.1-0.2-1.7-0.2
	c-0.8,0-1.7,0-2.5,0c-0.6,0-1.2,0-1.6-0.2c-0.4-0.2-0.7-0.5-0.8-1c-0.1-0.5-0.1-1.1-0.1-1.6c0-0.9,0-1.9,0-2.8c0-3,0-5.9,0-8.9
	c0-0.6,0-1.1,0-1.7c0-0.3,0-0.6,0-0.9c0-0.1,0-0.2,0-0.3c0.1-0.4,0.1-0.7,0.3-0.9c0.2-0.3,0.4-0.5,0.8-0.6
	C85,83.9,85.4,83.9,85.4,83.9z"
          />
        )}
        {inverted && (
          <Path
            color={color}
            d="M7.6,0c36.3,0,72.5,0,108.8,0c9.2,0,18.4,0,27.6,0c2,0,4.1,0,6.1,0c0.5,0,1.1,0,1.6,0c0.4,0,0.7,0,1,0
	c0.2,0,0.4,0,0.6,0.1c0.4,0.1,0.9,0.2,1.3,0.4c0.8,0.3,1.5,0.7,2.2,1.2c0.3,0.2,0.7,0.6,0.9,0.8c0.1,0.1,0.1,0.1,0.2,0.2
	c0.2,0.2,0.4,0.4,0.5,0.7c0.6,0.8,1,1.8,1.3,2.9C160,7,160,8.1,160,9.1c0,1.5,0,3,0,4.5c0,6.4,0,12.7,0,19.1c0,30.2,0,60.4,0,90.6
	c0,7.8,0,15.7,0,23.5c0,1.3,0,2.7,0,4c0,0.5,0.1,1.2,0,1.7c0,0.2,0,0.3,0,0.5c-0.1,0.6-0.2,1.1-0.4,1.6c-0.2,0.5-0.4,1-0.7,1.5
	c-0.2,0.3-0.4,0.5-0.5,0.7c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4,0.3-0.8,0.7-1.3,1
	c-0.7,0.5-1.6,0.8-2.5,1c-0.9,0.3-2.2,0.2-3.3,0.2c-1.9,0-3.8,0-5.6,0c-7,0-13.9,0-20.9,0c-29.2,0-58.5,0-87.8,0c-7,0-13.9,0-20.9,0
	c-1.9,0-3.8,0-5.7,0c-1.2,0-2.4,0.1-3.3-0.2c-0.5-0.2-1.1-0.3-1.6-0.5c-1.7-0.8-3.2-2.3-4-4c-0.2-0.5-0.4-1-0.5-1.6
	c0-0.3-0.1-0.5-0.1-0.8c-0.1-0.4,0-0.8,0-1.2c0-0.7,0-1.4,0-2c0-1.9,0-3.8,0-5.7c0-7.8,0-15.7,0-23.5c0-28.6,0-57.3,0-85.9
	c0-6.7,0-13.3,0-19.9c0-1.7,0-3.4,0-5.1c0-0.5,0-0.9,0-1.4c0-0.3,0-0.6,0-0.8c0-0.2,0-0.3,0-0.5c0-0.2,0.1-0.4,0.1-0.7
	c0.2-0.5,0.3-1.1,0.5-1.6c0.9-2,2.5-3.3,4.5-4.2c0.5-0.2,1-0.3,1.5-0.4C7.1,0.1,7.3,0,7.6,0z M57.9,8c-0.1,0.1-0.4,0-0.6,0
	c-0.4,0.1-0.8,0.2-1,0.5c-0.6,0.8-0.4,2.6,0.3,3.1c0.7,0.4,2.1,0.3,3.2,0.3c2.4,0,4.9,0,7.3,0c0.9,0,2.6-0.1,3.1,0.2
	c0.3,0.2,0.4,0.4,0.5,0.8c0.1,0.3-0.1,0.8-0.2,1c-0.3,0.8-0.6,1.4-1,2.1c-0.3,0.4-0.5,0.8-0.8,1.2c-0.2,0.2-0.4,0.5-0.6,0.7
	c-0.3,0.4-0.6,0.7-1.1,0.9c-0.2,0.1-0.6,0.1-0.9,0c-0.6-0.2-1-0.5-1.5-0.7c-1.2-0.6-2.5-1-3.8-1.5c-0.6-0.2-1.3-0.3-1.9-0.4
	c-0.2,0-0.4,0-0.6-0.1c-0.8-0.2-1.6-0.1-2.1,0.3c-0.7,0.5-0.6,2.3-0.1,2.9c0.4,0.5,1,0.5,1.6,0.7c0.2,0,0.3,0,0.5,0
	c0.3,0.1,0.6,0.1,0.9,0.2c0.7,0.2,1.4,0.4,2,0.6c0.4,0.2,0.7,0.3,0.9,0.6c0.1,0.1,0.2,0.3,0.2,0.5c-0.2,0.8-1.2,1-1.9,1.2
	c-0.6,0.2-1.4,0.3-2.1,0.5c-0.2,0-0.3,0-0.5,0c-0.5,0.1-1,0.2-1.4,0.5c-0.4,0.3-0.5,0.8-0.6,1.4c0,0.2,0,0.7,0,0.9
	c0.2,0.6,0.3,1,0.8,1.2c0.4,0.1,1,0.2,1.5,0.1c0.2,0,0.5-0.1,0.7-0.1c0.7-0.2,1.4-0.3,2.1-0.5c0.4-0.1,0.7-0.2,1-0.3
	c0.5-0.2,1.1-0.4,1.6-0.6c0.2-0.1,0.4-0.2,0.7-0.3c0.3-0.1,0.6-0.2,0.8-0.4c0.4-0.2,1.2-0.7,1.9-0.5c1,0.3,1.7,1.5,2.3,2.3
	c0.3,0.5,0.7,1.1,0.9,1.6c0.2,0.5,0.5,1.1,0.7,1.7c0.2,0.6,0.4,1.2,0.5,1.9c0.2,0.6,0.1,1.2,0.3,1.9c0,0.2,0,0.4,0,0.7
	c0.1,0.3,0,0.6,0,0.9c0,0.3,0,0.7,0,1.1c0,0.3,0,0.7,0,1c0,0.1,0,0.3,0,0.4c-0.1,0.5-0.2,0.9-0.5,1.1C70.7,40.1,69.3,40,68,40
	c-2.5,0-5,0-7.6,0c-0.6,0-1.1,0-1.7,0c-0.3,0-0.6,0-0.8,0c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5,0.1-0.7,0.2c-0.5,0.2-0.7,0.6-0.9,1.1
	c-0.2,0.6-0.1,1.7-0.1,2.4c0,1.6,0,3.2,0,4.7c0,1-0.1,2.2,0.3,2.8c0.3,0.4,0.7,0.5,1.2,0.7c0.3,0.1,0.8,0,1.2,0c0.8,0,1.5,0,2.3,0
	c2.4,0,4.8,0,7.2,0c1.2,0,2.5-0.1,3.2,0.4c0.3,0.2,0.4,0.6,0.5,1c0,0.2,0.1,0.7,0,0.9c-0.1,0.6-0.2,1.1-0.7,1.4
	c-0.7,0.4-2.2,0.3-3.3,0.3c-2.6,0-5.1,0-7.7,0c-0.5,0-1,0-1.5,0c-0.2,0-0.6,0-0.8,0c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5,0.1-0.7,0.2
	c-0.4,0.1-0.6,0.4-0.8,0.8C56,57.3,56,58,56,58.6c0,0.8,0,1.7,0,2.5c0,1.2,0,2.4,0,3.6c0,1.5-0.1,2.7,1.1,3.1
	c0.4,0.1,0.9,0.1,1.5,0.1c0.7,0,1.4,0,2.1,0c2.4,0,4.8,0,7.2,0c1,0,2.5-0.1,3.2,0.2c0.4,0.2,0.7,0.5,0.8,0.9
	c0.3,0.8,0.1,2.2-0.5,2.5c-0.7,0.5-2.2,0.4-3.5,0.4c-2.7,0-5.3,0-8,0c-1.2,0-2.8-0.2-3.4,0.5c-0.3,0.3-0.3,0.6-0.4,1.1
	c0,0.2-0.1,0.6,0,0.8c0.1,0.6,0.2,1,0.6,1.4C57.1,76,58,76,58.9,76c0.6,0,1.1,0,1.7,0c3.2,0,6.4,0,9.6,0c1,0,1.9,0,2.9,0
	c0.6,0,1.2,0,1.7-0.1c0.4-0.1,0.8-0.3,0.9-0.6c0.4-0.6,0.3-1.7,0.3-2.7c0-1.6,0-3.3,0-4.9c0-1.1,0.1-2.4-0.4-3.1
	c-0.5-0.7-2-0.6-3.3-0.6c-2.8,0-5.7,0-8.6,0c-1.2,0-2.5,0.1-3.2-0.4c-0.3-0.2-0.4-0.6-0.5-1c0-0.2-0.1-0.7,0-0.9
	c0.1-0.5,0.2-1,0.6-1.3c0.8-0.6,2.6-0.4,3.9-0.4c2.4,0,4.7,0,7.1,0c0.6,0,1.1,0,1.7,0c0.3,0,0.6,0,0.9,0c0.1,0,0.2,0,0.3,0
	c0.2,0,0.4-0.1,0.6-0.1c1.2-0.4,1.1-1.5,1.1-3.1c0-1.2,0-2.4,0-3.6c0-0.8,0-1.7,0-2.5c0-0.6,0-1.2-0.2-1.7c-0.1-0.4-0.4-0.6-0.8-0.8
	c-0.2-0.1-0.5-0.1-0.7-0.2c-0.1,0-0.2,0-0.3,0c-0.2,0-0.5,0-0.8,0c-0.5,0-1,0-1.5,0c-2.6,0-5.2,0-7.7,0c-1.1,0-2.7,0.1-3.3-0.3
	c-0.5-0.3-0.5-0.7-0.7-1.4c-0.1-0.3,0-0.8,0-0.9c0.1-0.4,0.2-0.8,0.5-1c0.7-0.5,2-0.4,3.2-0.4c2.8,0,5.7,0,8.5,0
	c1.3,0,2.8,0.1,3.4-0.7c0.3-0.4,0.3-1.2,0.3-1.9c0-0.9,0-1.8,0-2.7c0-0.4,0-0.7,0-1.1c0-0.2,0-0.4,0-0.5c0-0.2,0-0.4,0-0.7
	c0-0.1,0-0.3,0-0.4c0-0.7-0.1-1.3-0.1-2c-0.1-0.6-0.1-1.2-0.3-1.8c-0.1-0.3-0.1-0.6-0.2-0.9c-0.3-0.9-0.5-1.8-0.8-2.6
	c-0.6-1.5-1.3-2.8-2.1-4c-0.3-0.4-0.6-0.8-0.9-1.2c-0.2-0.3-0.5-0.7-0.7-1.1c-0.1-0.4,0-0.9,0.2-1.2c0.4-0.8,1-1.4,1.5-2.1
	c1-1.5,1.8-3.2,2.4-5c0.2-0.6,0.3-1.3,0.5-1.9c0.1-0.4,0.1-0.9,0.3-1.4c0-0.2,0-0.4,0-0.6c0.1-0.3,0.1-0.9,0-1.2
	c-0.1-0.4-0.4-0.7-0.8-0.9c-0.2-0.1-0.5-0.1-0.8-0.2c-0.1,0-0.2,0-0.3,0c-0.3,0-0.6,0-0.9,0c-0.6,0-1.2,0-1.8,0
	C66.8,8,62.3,8,57.9,8z M86,8c-0.1,0.1-0.4,0-0.6,0c-0.4,0.1-0.7,0.2-0.9,0.4c-0.4,0.4-0.6,1.6-0.4,2.4c0.1,0.4,0.4,0.8,0.8,0.9
	c0.2,0.1,0.5,0.1,0.7,0.2c0.1,0,0.2,0,0.3,0c0.2,0,0.6,0,0.8,0c0.5,0,1.1,0,1.6,0c2.4,0,4.9,0,7.4,0c8.6,0,17.1,0,25.7,0
	c2.3,0,4.5,0,6.8,0c1.1,0,2.4-0.1,3,0.3c0.5,0.3,0.5,0.7,0.7,1.4c0,0.2,0,0.5,0,0.7c0,0.1,0,0.2,0,0.4c-0.1,0.4-0.2,0.7-0.5,0.9
	c-0.7,0.5-2.2,0.4-3.4,0.4c-2.6,0-5.2,0-7.7,0c-8.7,0-17.4,0-26,0c-2.2,0-4.4,0-6.6,0c-1.1,0-2.4-0.1-3,0.4
	c-0.6,0.5-0.6,1.5-0.6,2.6c0,1.9,0,3.8,0,5.7c0,1-0.1,2,0.3,2.6c0.5,0.7,1.6,0.7,2.8,0.7c2.1,0,4.2,0,6.4,0c9.2,0,18.4,0,27.6,0
	c2.3,0,4.6,0,6.9,0c1.2,0,2.7-0.1,3.4,0.4c0.3,0.2,0.4,0.7,0.5,1.1c0,0.2,0,0.3,0,0.5c0,0.7-0.1,1.3-0.6,1.6
	c-0.8,0.6-2.5,0.4-3.8,0.4c-3,0-6,0-9.1,0c-8.4,0-16.8,0-25.2,0c-2,0-4,0-6.1,0c-1.1,0-2.2-0.1-2.7,0.5C83.9,33,84,34.1,84,35.2
	c0,1.7,0,3.4,0,5c0,0.8-0.1,1.9,0.1,2.6c0.4,1.2,1.6,1.1,3.2,1.1c2.4,0,4.8,0,7.2,0c9.5,0,18.9,0,28.4,0c1.9,0,3.8,0,5.7,0
	c1,0,2.1-0.1,2.6,0.3c0.4,0.3,0.5,0.7,0.7,1.2c0,0.2,0,0.4,0,0.5c0,0.8-0.2,1.4-0.7,1.7c-0.5,0.3-1.3,0.3-2.2,0.3
	c-0.6,0-1.2,0-1.7,0c-3.1,0-6.3,0-9.4,0c-8.3,0-16.5,0-24.8,0c-2,0-4,0-6,0c-1.1,0-2.2-0.1-2.7,0.5C83.9,49,84,50.1,84,51.2
	c0,1.6,0,3.3,0,4.9c0,0.4,0,0.8,0,1.2c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.6c0.1,0.5,0.3,0.9,0.7,1.1c0.7,0.4,2,0.3,3,0.3
	c2.3,0,4.6,0,6.9,0c9.4,0,18.9,0,28.3,0c1.9,0,3.8,0,5.7,0c1,0,2.1-0.1,2.7,0.3c0.5,0.3,0.5,0.7,0.7,1.4c0,0.2,0,0.5,0,0.7
	c0,0.1,0,0.2,0,0.4c-0.1,0.4-0.2,0.7-0.5,0.9c-0.7,0.5-1.9,0.4-3.1,0.4c-2.2,0-4.5,0-6.7,0c-9.5,0-19,0-28.5,0c-1.9,0-3.8,0-5.7,0
	c-1,0-2.1-0.1-2.7,0.3c-0.3,0.2-0.5,0.5-0.6,0.9c-0.2,0.6-0.1,1.8-0.1,2.6c0,1.7,0,3.3,0,5c0,1.1-0.1,2.2,0.5,2.8
	c0.6,0.6,2.2,0.5,3.5,0.5c2.9,0,5.9,0,8.8,0c12,0,23.9,0,35.9,0c4.6,0,9.2,0,13.8,0c1,0,1.9,0,2.9,0c0.9,0,1.7,0,2.2-0.5
	c0.7-0.7,0.6-2.8-0.2-3.3c-0.7-0.4-2.1-0.3-3.2-0.3c-2.4,0-4.7,0-7.1,0c-13.1,0-26.2,0-39.2,0c-3.1,0-6.3,0-9.4,0
	c-1.3,0-3.1,0.2-3.9-0.4c-0.4-0.3-0.4-0.7-0.6-1.3c0-0.2,0-0.5,0-0.7c0-0.1,0-0.2,0-0.4c0.1-0.5,0.3-0.8,0.7-1.1
	c0.2-0.1,0.6-0.2,0.9-0.2c0.1,0,0.2,0,0.3,0c0.3,0,0.6,0,0.9,0c0.6,0,1.2,0,1.8,0c3.1,0,6.2,0,9.3,0c12.5,0,25.1,0,37.6,0
	c2.9,0,5.9,0,8.8,0c1.4,0,2.8,0.1,3.4-0.7c0.3-0.5,0.3-1.4,0.3-2.3c0-1.5,0-3,0-4.4c0-5.7,0-11.4,0-17.1c0-8.6,0-17.2,0-25.8
	c0-2.1,0-4.2,0-6.3c0-1.1,0.1-2.2-0.5-2.8C151,8,150.1,8,149.2,8c-0.8,0-1.6,0-2.4,0c-4,0-8.1,0-12.1,0C118.5,8,102.2,8,86,8z
	 M9.7,8c-0.1,0-0.5,0.1-0.6,0.1C8.8,8.2,8.5,8.4,8.3,8.7C8.1,9,8.1,9.3,8,9.7C8,9.8,8,9.9,8,10c0,0.3,0,0.6,0,0.9c0,0.6,0,1.2,0,1.8
	c0,3.1,0,6.2,0,9.3c0,1,0,2,0,3c0,0.6,0,1.2,0.1,1.7c0.1,0.5,0.4,0.9,0.9,1.1c0.4,0.2,1.1,0.2,1.7,0.2c0.9,0,1.8,0,2.6,0
	c0.7,0,1.3,0,1.8,0.2c0.5,0.2,0.7,0.6,0.8,1.1c0.1,0.2,0.1,0.8,0,1.1c0,0.2,0,0.4-0.1,0.6c-0.2,0.5-0.5,0.8-1,1
	C14.5,32,13.8,32,13.3,32c-0.9,0-1.8,0-2.6,0c-1.1,0-1.9,0.1-2.3,0.7C8,33.1,8,33.9,8,34.7c0,1.1,0,2.1,0,3.2c0,4.3,0,8.6,0,12.8
	c0,6.5,0,13,0,19.5c0,1,0,2.1,0,3.1c0,0.8,0,1.5,0.3,2c0.2,0.4,0.6,0.5,1,0.6c0.2,0,0.7,0.1,0.9,0.1c0.2,0,0.3,0,0.5-0.1
	c0.4-0.1,0.8-0.3,0.9-0.6c0.3-0.5,0.3-1.5,0.3-2.3c0-1.5,0-3.1,0-4.6c0-7.8,0-15.6,0-23.5c0-1.9,0-3.7,0-5.6c0-1-0.1-2,0.3-2.6
	c0.3-0.5,0.7-0.5,1.4-0.7c0.3,0,0.8,0,0.9,0c0.4,0.1,0.8,0.2,1,0.5c0.4,0.6,0.4,1.7,0.4,2.8c0,1.3,0,2.6,0,4c0,8.3,0,16.6,0,24.9
	c0,1.6,0,3.2,0,4.7c0,1,0,1.9,0.4,2.4c0.5,0.7,2.5,0.8,3.1,0.1C20,75,20,74,20,73c0-1.8,0-3.6,0-5.3c0-7.5,0-14.9,0-22.4
	c0-2,0-3.9,0-5.9c0-1-0.1-2.1,0.3-2.7c0.3-0.5,0.7-0.5,1.4-0.7c0.2,0,0.5,0,0.7,0c0.1,0,0.2,0,0.4,0c0.4,0.1,0.7,0.2,0.9,0.5
	c0.4,0.6,0.4,1.6,0.4,2.7c0,1.8,0,3.6,0,5.3c0,8.1,0,16.2,0,24.3c0,1.2,0,2.5,0,3.7c0,1.1-0.1,2.4,0.5,2.9c0.3,0.3,0.6,0.3,1.1,0.4
	c0.2,0,0.5,0.1,0.7,0c0.2,0,0.4,0,0.6-0.1c1.2-0.4,1.1-1.6,1.1-3.2c0-2.2,0-4.5,0-6.7c0-7.7,0-15.5,0-23.2c0-2.2,0-4.4,0-6.7
	c0-1,0.1-2.5-0.2-3.2C27.4,32,26.4,32,25,32c-0.8,0-1.6,0-2.4,0c-0.5,0-1,0-1.4-0.1c-0.6-0.2-1-0.5-1.2-1c-0.1-0.2,0-0.4-0.1-0.6
	c-0.1-0.3,0-0.8,0-1.1c0.1-0.5,0.4-0.9,0.8-1.1c0.5-0.2,1.1-0.2,1.7-0.2c0.8,0,1.7,0,2.6,0c0.9,0,1.8,0,2.3-0.5
	c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.4,0.1-0.9,0.1-1.5c0-0.7,0-1.4,0-2c0-3.6,0-7.3,0-10.9c0-0.5,0-1.1,0-1.6c0-0.3,0-0.6,0-0.8
	c0-0.1,0-0.2,0-0.3c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.4-0.4-0.6-0.8-0.8C26.8,8,26.5,8,26.2,8C26,7.9,25.5,8,25.3,8
	c-0.5,0.1-0.8,0.3-1,0.6c-0.4,0.7-0.3,2.2-0.3,3.2c0,2.6,0,5.3,0,7.9c0,1.1,0.1,2.7-0.3,3.4c-0.3,0.5-0.7,0.5-1.4,0.7
	c-0.2,0-0.5,0-0.7,0c-0.1,0-0.2,0-0.4,0c-0.4-0.1-0.8-0.3-1-0.6c-0.4-0.7-0.3-2.3-0.3-3.5c0-2.7,0-5.4,0-8.1c0-1,0.1-2.3-0.3-3
	c-0.2-0.4-0.6-0.5-1-0.6c-0.2,0-0.7-0.1-0.9-0.1c-0.3,0-0.5,0.1-0.8,0.2C15.8,8.6,16,10,16,11.5c0,2.9,0,5.7,0,8.6
	c0,1.3,0.2,2.9-0.6,3.5c-0.2,0.1-0.4,0.3-0.7,0.3c-0.1,0-0.2,0-0.4,0c-0.2,0-0.6,0-0.8,0c-0.5-0.1-0.9-0.3-1.2-0.7
	c-0.5-0.7-0.3-2.4-0.3-3.5c0-2.7,0-5.4,0-8.1c0-1.2,0.1-2.7-0.5-3.3C11.1,8.1,10.5,8,9.7,8z M33.7,8c-0.1,0-0.5,0.1-0.6,0.1
	c-0.4,0.1-0.8,0.4-0.9,0.8c-0.3,0.6-0.2,1.7-0.2,2.6c0,1.8,0,3.7,0,5.5c0,1.2,0,2.2,0.7,2.7c0.5,0.3,1.2,0.3,1.9,0.3
	c0.9,0,1.8,0,2.7,0c0.7,0,1.3,0,1.8,0.2c0.5,0.2,0.7,0.6,0.8,1.2c0,0.2,0.1,0.7,0,0.9c0,0.2,0,0.4-0.1,0.6c-0.1,0.4-0.4,0.8-0.8,0.9
	C38.6,24,37.9,24,37.3,24c-0.9,0-1.8,0-2.7,0c-0.6,0-1.2,0-1.7,0.2c-0.4,0.1-0.6,0.4-0.8,0.8c-0.3,0.7-0.2,1.9,0.2,2.4
	c0.2,0.2,0.4,0.4,0.7,0.5C33.5,28,34,28,34.5,28c0.7,0,1.4,0,2.1,0c3.6,0,7.1,0,10.7,0c0.6,0,1.1,0,1.7,0c0.3,0,0.6,0,0.9,0
	c0.1,0,0.2,0,0.3,0c0.2,0,0.4-0.1,0.6-0.1c0.2-0.1,0.5-0.2,0.6-0.4c0.4-0.4,0.6-1.6,0.4-2.4c-0.1-0.4-0.4-0.8-0.8-0.9
	C50.5,24,49.9,24,49.3,24c-0.9,0-1.8,0-2.7,0c-0.7,0-1.3,0-1.8-0.2c-0.5-0.2-0.7-0.6-0.8-1.2c0-0.2-0.1-0.7,0-0.9
	c0-0.2,0-0.4,0.1-0.6c0.1-0.4,0.4-0.8,0.8-0.9C45.3,20,46,20,46.6,20c0.9,0,1.8,0,2.7,0c0.6,0,1.2,0,1.7-0.2c1-0.4,0.9-1.5,0.9-2.9
	c0-1.8,0-3.7,0-5.5c0-1.1,0.1-2.3-0.5-2.9C51,8.1,49.8,7.9,49,8.1c-0.4,0.1-0.8,0.4-0.9,0.8c-0.2,0.4-0.2,1.1-0.2,1.7
	c0,0.9,0,1.8,0,2.7c0,0.7,0,1.3-0.2,1.8c-0.2,0.5-0.6,0.7-1.2,0.8c-0.2,0-0.7,0.1-0.9,0c-0.2,0-0.4,0-0.6-0.1c-0.5-0.2-0.8-0.5-1-1
	c-0.1-0.4-0.1-1.1-0.1-1.6c0-0.9,0-1.7,0-2.6c0-0.8,0-1.5-0.3-1.9C43.3,8.2,42.7,8,41.9,8c-0.3,0-0.6,0-0.8,0.1
	c-0.4,0.1-0.8,0.4-0.9,0.8C39.9,9.4,40,10,40,10.7c0,0.9,0,1.8,0,2.6c0,0.5,0,1-0.1,1.4c-0.2,0.6-0.5,1-1,1.2
	c-0.2,0.1-0.4,0-0.6,0.1c-0.3,0.1-0.8,0-1.1,0c-0.5-0.1-0.9-0.4-1.1-0.8c-0.2-0.5-0.2-1.1-0.2-1.8c0-0.9,0-1.7,0-2.6
	c0-0.6,0-1.3-0.2-1.8C35.5,8.2,34.8,8,33.7,8z M137.8,12c2.3,0,4.6,0,6.9,0c1.1,0,2.3-0.1,2.8,0.6c0.5,0.8,0.4,2.4,0.4,3.6
	c0,2.8,0,5.7,0,8.5c0,8.9,0,17.9,0,26.9c0,2.6,0,5.2,0,7.8c0,0.7,0,1.4,0,2.1c0,0.5,0,1-0.1,1.4c-0.5,1.2-1.6,1.1-3.2,1.1
	c-1.6,0-3.3,0-5,0c-0.8,0-2.1,0.1-2.7-0.1c-0.4-0.2-0.7-0.4-0.9-0.8c-0.2-0.5-0.2-1.1-0.2-1.7c0-0.8,0-1.7,0-2.5
	c0-1.1,0-2.1-0.7-2.5c-0.6-0.4-1.5-0.3-2.4-0.3c-1.6,0-3.2,0-4.9,0c-6.2,0-12.5,0-18.7,0c-5.2,0-10.3,0-15.5,0c-1.1,0-2.1,0-3.2,0
	c-0.9,0-1.8,0-2.2-0.6c-0.6-0.8-0.5-2.6,0.3-3.2c0.6-0.4,1.9-0.3,2.9-0.3c2.1,0,4.2,0,6.4,0c9.6,0,19.2,0,28.8,0c2,0,3.9,0,5.8,0
	c1,0,2.1,0.1,2.7-0.3c0.4-0.3,0.5-0.7,0.6-1.4c0-0.3,0-0.5,0-0.8c0-0.5,0-1,0-1.5c0-1.6,0-3.2,0-4.8c0-1.1,0.1-2.2-0.5-2.7
	c-0.5-0.5-1.7-0.5-2.8-0.5c-2.1,0-4.2,0-6.3,0c-8.7,0-17.3,0-26,0c-2.9,0-5.8,0-8.7,0c-1,0-2.1,0.1-2.8-0.2
	c-0.5-0.2-0.7-0.6-0.8-1.1c0-0.1,0-0.2,0-0.4c-0.2-0.7,0.2-1.7,0.5-2c0.7-0.5,1.9-0.4,3.1-0.4c2.2,0,4.4,0,6.7,0
	c9.5,0,18.9,0,28.4,0c2,0,4.1,0,6.1,0c1.4,0,2.5,0.1,2.9-0.9c0.3-0.6,0.2-1.7,0.2-2.6c0-1.7,0-3.5,0-5.2c0-1.1,0.1-2.2-0.5-2.7
	c-0.5-0.5-1.7-0.5-2.8-0.5c-2.1,0-4.3,0-6.4,0c-8.8,0-17.6,0-26.4,0c-2.8,0-5.5,0-8.2,0c-0.9,0-2.1,0.1-2.8-0.2
	c-0.5-0.2-0.7-0.6-0.8-1.1c0-0.1,0-0.2,0-0.4c-0.2-0.7,0.2-1.7,0.5-2c0.7-0.5,2-0.4,3.2-0.4c2.3,0,4.6,0,7,0c8.2,0,16.5,0,24.7,0
	c2.5,0,5.1,0,7.6,0c0.8,0,1.5,0,2.3,0c0.5,0,1,0,1.4-0.1c0.5-0.1,0.9-0.4,1.1-0.9c0.2-0.4,0.2-1.1,0.2-1.7c0-0.9,0-1.7,0-2.6
	c0-0.5,0-1,0.1-1.4c0.2-0.6,0.5-1,1-1.2c0.2-0.1,0.4-0.1,0.6-0.1C137.7,12,137.8,12,137.8,12z M141.6,16c-0.1,0-0.5,0.1-0.6,0.1
	c-0.3,0.1-0.6,0.3-0.8,0.6c-0.4,0.6-0.3,1.7-0.3,2.7c0,1.9,0,3.9,0,5.8c0,8.4,0,16.7,0,25.1c0,2.1,0,4.1,0,6.2
	c0,1.1-0.1,2.2,0.4,2.9c0.2,0.3,0.6,0.4,1,0.5c0.1,0,0.2,0,0.3,0c0.1,0,0.4,0,0.6,0c0.7-0.1,1-0.2,1.4-0.6c0.4-0.6,0.3-1.8,0.3-2.8
	c0-2.1,0-4.1,0-6.2c0-8.7,0-17.4,0-26.1c0-1.7,0-3.3,0-5c0-1,0-2-0.4-2.5C143.2,16.1,142.5,16,141.6,16z M33.9,32
	c-0.1,0.1-0.4,0-0.6,0c-0.5,0.1-0.9,0.3-1.1,0.7c-0.4,0.6-0.3,1.7-0.3,2.6c0,1.8,0,3.6,0,5.4c0,1,0,2,0.4,2.6
	c0.3,0.4,0.7,0.4,1.3,0.6c0.3,0.1,0.7,0,1,0.1c0.6,0.2,1,0.5,1.2,1c0.1,0.2,0,0.4,0.1,0.6c0,0.2,0.1,0.5,0,0.7c0,0.2,0,0.4-0.1,0.6
	c-0.1,0.4-0.4,0.8-0.8,0.9c-0.5,0.2-1.4,0.1-2,0.3c-0.5,0.2-0.9,0.5-1,1.1c-0.2,0.6-0.1,1.7-0.1,2.5c0,1.6,0,3.2,0,4.8
	c0,1-0.1,2.2,0.3,2.8c0.2,0.3,0.6,0.5,1.1,0.6c0.5,0.1,1.1,0,1.5,0.2c0.8,0.3,1.1,0.9,1.1,2c0,0.3,0,0.6-0.1,0.8
	c-0.2,0.5-0.5,0.8-1,1c-0.4,0.1-1,0-1.5,0.2c-0.5,0.1-0.9,0.3-1.1,0.7c-0.4,0.6-0.3,1.7-0.3,2.6c0,1.8,0,3.7,0,5.5
	c0,1.1-0.1,2.1,0.5,2.7c0.6,0.6,2,0.5,3.1,0.5c1.1,0,2.9,0.1,3.6-0.3c0.3-0.2,0.5-0.5,0.6-0.8c0-0.1,0-0.3,0.1-0.4
	c0-0.2,0.1-0.6,0-0.8c0-0.2-0.1-0.4-0.1-0.6c-0.1-0.4-0.4-0.8-0.8-0.9c-0.5-0.2-1.2-0.1-1.7-0.2c-0.6-0.2-1-0.5-1.2-1
	c-0.1-0.2,0-0.4-0.1-0.6c0-0.2,0-0.6,0-0.8c0.1-0.6,0.3-1,0.8-1.2c0.6-0.4,1.8-0.3,2.8-0.3c1.6,0,3.1,0,4.7,0c0.4,0,0.8,0,1.2,0
	c0.2,0,0.4,0,0.6,0c0.1,0,0.3,0,0.4,0c0.6,0.1,1.1,0.3,1.3,0.9c0.2,0.6,0.2,1.6,0,2.2c-0.1,0.2-0.3,0.5-0.5,0.6
	c-0.7,0.5-2,0.2-2.7,0.7c-0.4,0.3-0.4,0.7-0.6,1.3c0,0.2,0,0.7,0,0.8c0,0.2,0,0.3,0.1,0.4c0.1,0.3,0.3,0.6,0.5,0.8
	c0.7,0.5,2.4,0.3,3.5,0.3c0.9,0,2.2,0.1,2.9-0.2c1-0.4,0.9-1.5,0.9-2.9c0-1.8,0-3.7,0-5.5c0-1.1,0.1-2.2-0.4-2.9
	c-0.3-0.4-0.7-0.4-1.3-0.6c-0.3-0.1-0.7,0-1-0.1c-0.4-0.1-0.6-0.2-0.9-0.5C48,63,47.8,61.8,48.1,61c0.2-0.6,0.6-0.9,1.2-1
	c0.6-0.1,1.2,0,1.7-0.2c0.4-0.2,0.7-0.5,0.8-0.9c0-0.2,0.1-0.4,0.1-0.6c0-0.3,0-0.5,0-0.8c0-0.5,0-1,0-1.5c0-1.6,0-3.1,0-4.6
	c0-1,0.1-2.1-0.3-2.7c-0.2-0.3-0.6-0.5-1.1-0.6c-0.5-0.1-1.1,0-1.5-0.2C48.3,47.6,48,47,48,46c0-0.3,0-0.5,0-0.8
	c0.2-0.6,0.5-1,1-1.2c0.2-0.1,0.4-0.1,0.6-0.1c0.1,0,0.1,0,0.2,0c0.2,0,0.4,0,0.6,0c0.4-0.1,0.8-0.2,1.1-0.4
	c0.5-0.5,0.5-1.6,0.5-2.6c0-1.9,0-3.8,0-5.7c0-1.1,0-2.1-0.5-2.6C51,32,50.2,32,49.3,32c-0.9,0-1.7,0-2.6,0C42.5,32,38.2,32,33.9,32
	z M57.9,32c-0.1,0.1-0.4,0-0.6,0c-0.5,0.1-0.8,0.3-1.1,0.6c-0.3,0.5-0.4,1.7-0.2,2.4c0.1,0.2,0.3,0.5,0.5,0.6
	c0.6,0.4,1.6,0.3,2.5,0.3c1.7,0,3.5,0,5.2,0c1.6,0,3,0.2,3.4-1.1c0.3-0.9,0-2.1-0.5-2.5c-0.6-0.5-1.8-0.4-2.9-0.4
	C62.2,32,60.1,32,57.9,32z M37.9,35.9c2.2,0,4.3,0,6.5,0c1.1,0,2.4-0.1,3,0.4c0.5,0.4,0.7,1.7,0.5,2.5C47.4,40.2,46,40,44.4,40
	c-1.7,0-3.5,0-5.2,0c-1,0-2,0-2.6-0.4c-0.6-0.5-0.8-2.2-0.3-2.9c0.2-0.3,0.6-0.5,1-0.6C37.5,36,37.7,36,37.9,35.9z M41.6,44
	c1,0,1.7,0.1,2.1,0.8c0.3,0.4,0.3,1.2,0.3,1.8c0,0.9,0,1.8,0,2.7c0,0.6,0,1.3,0.2,1.7c0.2,0.5,0.6,0.8,1.2,0.9
	c0.5,0.1,1.1,0,1.5,0.2c0.6,0.2,0.9,0.6,1,1.2c0.1,0.2,0,0.5,0,0.8c0,1-0.3,1.6-1.1,1.8c-0.4,0.1-1,0-1.5,0.2
	c-0.6,0.1-1,0.4-1.2,0.9C43.9,57.4,44,58,44,58.6c0,0.9,0,1.8,0,2.7c0,0.7,0,1.3-0.2,1.8c-0.2,0.4-0.5,0.7-0.9,0.8
	c-0.2,0.1-0.4,0.1-0.6,0.1c-0.3,0-0.8,0-0.9,0c-0.6-0.1-1-0.3-1.2-0.8c-0.2-0.5-0.2-1.1-0.2-1.8c0-0.9,0-1.8,0-2.7
	c0-0.6,0-1.2-0.2-1.7c-0.2-0.5-0.6-0.8-1.2-0.9c-0.5-0.1-1.1,0-1.5-0.2c-0.5-0.2-0.8-0.5-1-1C36,54.6,36,54.4,36,54.1
	c0-1,0.2-1.7,0.9-2c0.5-0.2,1.4-0.1,2-0.3c0.4-0.1,0.8-0.4,0.9-0.8c0.2-0.4,0.2-1.1,0.2-1.7c0-0.9,0-1.8,0-2.7c0-0.7,0-1.3,0.2-1.8
	c0.2-0.4,0.5-0.7,0.9-0.8C41.2,44,41.5,44,41.6,44z M33.9,84c-0.1,0.1-0.4,0-0.6,0c-0.4,0.1-0.7,0.2-0.9,0.4
	C32,84.9,32,85.7,32,86.6c0,0.8,0,1.5,0,2.3c0,1.3,0,2.6,0,3.9c0,1.5-0.1,2.7,1.1,3.1c0.5,0.2,1.1,0.1,1.7,0.1c1,0,1.9,0,2.9,0
	c3.2,0,6.5,0,9.7,0c0.6,0,1.2,0,1.7,0c0.3,0,0.6,0,0.9,0c0.1,0,0.2,0,0.3,0c0.3,0.1,0.7,0.1,0.9,0.2c0.4,0.3,0.6,0.6,0.7,1.2
	c0,0.2,0.1,0.7,0,0.9c-0.1,0.6-0.2,1.1-0.7,1.4c-0.4,0.3-1.1,0.3-1.9,0.3c-0.9,0-1.7,0-2.6,0c-3.1,0-6.2,0-9.3,0c-0.9,0-1.8,0-2.7,0
	c-0.6,0-1.2,0-1.7,0.1c-0.4,0.1-0.8,0.4-0.9,0.8c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.2-0.1,0.5,0,0.8c0,0.2,0,0.3,0,0.5
	c0.1,0.5,0.4,0.9,0.9,1.1c0.7,0.3,1.9,0.2,2.8,0.2c2,0,3.9,0,5.9,0c6.9,0,13.7,0,20.6,0c1.9,0,3.7,0,5.6,0c0.8,0,2-0.1,2.5,0.2
	c0.4,0.2,0.7,0.7,0.5,1.4c-0.1,0.5-0.3,0.9-0.5,1.3c-0.5,1.1-1.1,2-1.7,2.8c-0.4,0.5-0.8,1-1.5,1.2c-0.2,0.1-0.6,0-0.8,0
	c-0.5-0.2-0.9-0.4-1.3-0.6c-0.9-0.5-1.9-0.9-3-1.2c-0.4-0.1-0.9-0.2-1.3-0.4c-0.4-0.1-0.8-0.1-1.2-0.2c-0.3-0.1-0.6-0.1-1-0.2
	c-0.3-0.1-0.6,0-0.9-0.1c-0.2,0-0.3,0-0.5,0c-0.3-0.1-0.7,0-1,0c-0.1,0-0.3,0-0.4,0c-0.2,0-0.5,0-0.7,0c-0.4,0-0.9,0-1.3,0
	c-2.8,0-5.5,0-8.3,0c-3,0-6.1,0-9.1,0c-1.2,0-3.1-0.2-3.8,0.3c-0.5,0.3-0.5,0.7-0.6,1.4c-0.1,0.3,0,0.8,0.1,0.9
	c0.1,0.4,0.2,0.6,0.4,0.8c0.6,0.6,1.7,0.5,2.8,0.5c1.3,0,2.5,0,3.8,0c3.9,0,7.8,0,11.7,0c1.2,0,2.5,0,3.7,0c0.4,0,0.9-0.1,1.3,0
	c0.2,0,0.4,0,0.6,0c0.1,0,0.2,0,0.4,0c0.3,0.1,0.6,0,0.8,0.1c0.4,0.1,0.8,0.1,1.2,0.2c0.8,0.2,1.6,0.3,2.2,0.6
	c0.5,0.2,1,0.4,1.2,0.8c0.1,0.2,0.1,0.5,0,0.6c-0.2,0.4-0.6,0.5-1,0.7c-0.8,0.3-1.6,0.5-2.5,0.7c-0.5,0.1-1,0.1-1.5,0.2
	c-0.1,0-0.1,0-0.2,0c-0.4,0.1-0.8,0-1.2,0c-0.7,0-1.5,0-2.3,0c-1.3,0-2.7,0-4,0c-4,0-8,0-12,0c-1,0-2,0-2.9,0c-0.7,0-1.5,0-1.9,0.3
	c-0.5,0.3-0.7,0.9-0.7,1.8c0,0.2,0,0.5,0.1,0.7c0.1,0.5,0.4,0.9,0.9,1.1c0.7,0.3,1.9,0.2,2.8,0.2c3.1,0,6.2,0,9.4,0
	c2.9,0,5.8,0,8.7,0c0.4,0,0.9,0,1.3,0c0.2,0,0.5,0,0.6,0c0.2,0,0.5,0,0.7,0c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0,0.6,0c0.1,0,0.1,0,0.2,0
	c0.3-0.1,0.6,0,0.9-0.1c0.3-0.1,0.7-0.1,1-0.2c0.3-0.1,0.7-0.1,1-0.2c0.5-0.1,1-0.2,1.5-0.4c1.1-0.4,2.1-0.8,3-1.2
	c0.4-0.2,0.8-0.5,1.4-0.6c0.2-0.1,0.5-0.1,0.8,0c0.7,0.2,1.2,0.8,1.6,1.3c0.8,1.2,1.8,2.5,2.2,4.2c0.1,0.4-0.1,0.7-0.2,0.9
	c-0.4,0.6-1.6,0.5-2.6,0.5c-3.2,0-6.4,0-9.7,0c-6.2,0-12.5,0-18.7,0c-1.6,0-3.1,0-4.7,0c-0.9,0-1.9,0-2.4,0.4
	c-0.6,0.5-0.6,1.6-0.6,2.7c0,1.7,0,3.4,0,5c0,0.4,0,0.8,0,1.2c0,0.2,0,0.4,0,0.6c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3,0.1,0.4
	c0.1,0.4,0.3,0.8,0.6,0.9c0.6,0.4,1.7,0.3,2.7,0.3c1.4,0,2.8,0,4.2,0c7.9,0,15.8,0,23.7,0c1.6,0,3.3,0,5,0c1.2,0,2.8-0.1,3.3,0.6
	c0.1,0.2,0.3,0.4,0.3,0.7c0,0.1,0,0.2,0,0.4c0,0.2,0,0.6,0,0.8c-0.1,0.5-0.3,0.9-0.7,1.2c-0.6,0.4-1.6,0.3-2.6,0.3
	c-1.9,0-3.7,0-5.6,0c-7.8,0-15.7,0-23.5,0c-1.5,0-3,0-4.6,0c-0.9,0-1.8,0-2.3,0.3c-0.4,0.3-0.5,0.7-0.7,1.2c0,0.1,0,0.3,0,0.4
	c0,0.2,0,0.4,0,0.6c0,0.4,0,0.9,0,1.3c0,1.6,0,3.2,0,4.9c0,1.1-0.1,2.2,0.5,2.8c0.6,0.6,1.8,0.5,2.9,0.5c2.3,0,4.6,0,6.9,0
	c8,0,15.9,0,23.8,0c2.1,0,4.2,0,6.3,0c1,0,2.2,0.1,2.9-0.3c0.9-0.5,1-2.7,0.1-3.4c-0.5-0.4-1.4-0.4-2.4-0.4c-1.5,0-2.9,0-4.4,0
	c-8.2,0-16.5,0-24.7,0c-1.6,0-3.3,0-4.9,0c-0.9,0-1.9,0-2.4-0.3c-0.5-0.3-0.5-0.7-0.7-1.4c0-0.2,0-0.5,0-0.7c0-0.1,0-0.2,0-0.4
	c0.1-0.4,0.2-0.7,0.5-0.9c0.7-0.5,1.9-0.4,3.1-0.4c2.2,0,4.5,0,6.7,0c7.1,0,14.1,0,21.2,0c1.8,0,3.5,0,5.3,0c1,0,2,0,2.6-0.4
	c0.6-0.5,0.6-1.5,0.6-2.6c0-1.9,0-3.7,0-5.6c0-1,0.1-2-0.3-2.6c-0.4-0.7-1.4-0.7-2.6-0.7c-1.7,0-3.4,0-5.1,0c-7.8,0-15.5,0-23.3,0
	c-1.8,0-3.6,0-5.5,0c-1.1,0-2.1,0-2.7-0.4c-0.3-0.2-0.4-0.6-0.5-0.9c0-0.1,0-0.2,0-0.4c0-0.2-0.1-0.5,0-0.7c0.1-0.6,0.2-1.1,0.7-1.4
	c0.5-0.4,1.4-0.3,2.3-0.3c1.5,0,3,0,4.6,0c7.8,0,15.6,0,23.3,0c2,0,4,0,6,0c1.3,0,2.4,0,2.8-0.9c0.1-0.4,0.2-1,0.1-1.5
	c0-0.2-0.1-0.5-0.1-0.7c-0.1-0.6-0.2-1.2-0.4-1.7c-0.1-0.4-0.2-0.9-0.4-1.3c-0.5-1.6-1.2-3-2-4.3c-0.4-0.6-0.8-1.2-1.2-1.7
	c-0.4-0.5-0.8-0.9-1-1.7c-0.1-0.4,0.1-0.7,0.2-0.9c0.4-0.7,0.9-1.3,1.4-2c0.7-1,1.3-2.1,1.8-3.3c0.2-0.5,0.4-1,0.6-1.4
	c0.3-0.7,0.4-1.4,0.6-2.1c0.1-0.3,0.1-0.5,0.2-0.8c0.1-0.3,0.1-0.6,0.2-1c0.2-1,0.1-2-0.6-2.4c-0.2-0.1-0.6-0.2-0.9-0.2
	c-0.1,0-0.2,0-0.3,0c-0.3-0.1-0.7,0-1,0c-0.7,0-1.3,0-2,0c-3.3,0-6.7,0-10,0c-0.8,0-1.7,0-2.5,0c-0.7,0-1.4,0-1.9-0.3
	c-0.5-0.3-0.5-0.7-0.7-1.4c0-0.2,0-0.5,0-0.7c0-0.1,0-0.2,0-0.4c0.1-0.4,0.2-0.7,0.5-0.9c0.6-0.4,1.6-0.4,2.7-0.4c1.7,0,3.5,0,5.2,0
	c2.6,0,5.2,0,7.9,0c1.2,0,2.6,0.1,3.2-0.5c0.5-0.5,0.4-1.5,0.5-2.5c0-1.3,0.1-2.7,0.1-4c0-0.8,0-1.6,0-2.4c0-0.6,0-1.2-0.2-1.6
	c-0.5-1.2-2.1-0.9-3.7-0.9c-2.9,0-5.7,0-8.6,0c-1.6,0-3-0.2-3.4,1.1C60,85.4,60,85.6,60,85.9c0,0.9,0.2,1.6,0.7,1.9
	c0.6,0.4,1.6,0.3,2.5,0.3c1.8,0,3.7,0,5.5,0c1.1,0,2.1,0,2.7,0.4c0.5,0.4,0.7,1.7,0.5,2.5c-0.4,1.3-1.8,1.1-3.4,1.1
	c-2.8,0-5.5,0-8.3,0c-1.3,0-2.9,0.2-3.6-0.4c-0.6-0.4-0.6-1.4-0.6-2.4c0-0.9,0-1.8,0-2.6c0-0.7,0-1.5-0.3-1.9
	c-0.2-0.3-0.6-0.5-1.1-0.6c-0.1,0-0.2,0-0.3,0c-0.2,0-0.7,0-0.9,0c-0.6,0.1-1,0.4-1.2,0.9C52,85.5,52,86.1,52,86.8
	c0,0.9,0,1.8,0,2.7c0,0.8,0,1.5-0.3,1.9c-0.6,0.8-2,0.7-3.4,0.7c-2.8,0-5.7,0-8.5,0c-1.4,0-2.9,0.2-3.5-0.7
	c-0.4-0.5-0.4-1.8-0.1-2.4c0.2-0.4,0.5-0.6,0.9-0.8c0.5-0.2,1.6-0.1,2.3-0.1c1.8,0,3.6,0,5.3,0c0.9,0,2,0.1,2.6-0.3
	c0.3-0.2,0.5-0.5,0.6-0.9c0-0.2,0-0.3,0.1-0.5c0.1-0.3,0-0.8-0.1-0.9c-0.1-0.5-0.3-0.8-0.6-1c-0.6-0.4-1.9-0.3-2.9-0.3
	C40.9,84,37.4,84,33.9,84z M109.9,84c-0.1,0.1-0.4,0-0.6,0c-0.3,0.1-0.6,0.2-0.8,0.3c-0.8,0.6-0.7,2.8,0.1,3.3
	c0.3,0.2,0.6,0.2,1,0.3c0.1,0,0.2,0,0.3,0c0.3,0.1,0.7,0,1,0c0.7,0,1.3,0,2,0c3.4,0,6.7,0,10.1,0c0.8,0,1.6,0,2.4,0
	c0.7,0,1.4,0,1.9,0.3c0.8,0.6,0.7,2,0.7,3.4c0,2.8,0,5.7,0,8.6c0,1.6,0,3.1,0,4.7c0,1,0,2-0.4,2.5c-0.2,0.3-0.6,0.4-0.9,0.5
	c-0.4,0.1-0.8,0.1-1.3,0.1c-0.7,0-1.4,0-2.1,0c-2.5,0-5,0-7.5,0c-1.2,0-2.6,0.1-3.3-0.4c-0.3-0.2-0.4-0.6-0.5-0.9c0-0.1,0-0.2,0-0.4
	c0-0.2-0.1-0.5,0-0.7c0.1-0.6,0.2-1.1,0.7-1.4c0.6-0.4,1.6-0.3,2.5-0.3c1.7,0,3.4,0,5.2,0c0.9,0,2.1,0.1,2.7-0.2
	c0.5-0.2,0.7-0.6,0.9-1.1c0.2-0.6,0.1-1.8,0.1-2.5c0-1.6,0-3.3,0-4.9c0-1.1,0.1-2.2-0.5-2.8c-0.6-0.6-2.3-0.5-3.5-0.5
	c-2.7,0-5.3,0-8,0c-1.2,0-2.7-0.1-3.4,0.4c-0.5,0.4-0.8,1.6-0.5,2.5c0.1,0.4,0.4,0.8,0.8,0.9c0.6,0.3,1.6,0.2,2.4,0.2
	c1.8,0,3.6,0,5.4,0c0.9,0,2-0.1,2.6,0.3c0.5,0.3,0.5,0.7,0.7,1.4c0,0.3,0,0.8,0,0.9c-0.1,0.4-0.1,0.6-0.3,0.8
	c-0.5,0.6-1.6,0.6-2.8,0.6c-1.7,0-3.4,0-5.1,0c-0.8,0-2-0.1-2.6,0.1c-1.2,0.4-1.1,1.6-1.1,3.1c0,1.3,0,2.7,0,4c0,0.7,0,1.4,0,2.2
	c0,0.5,0,1.1,0.1,1.5c0.1,0.2,0.2,0.5,0.4,0.6c0.2,0.2,0.4,0.3,0.6,0.4c0.4,0.1,0.9,0.1,1.4,0.1c0.7,0,1.4,0,2,0c3.6,0,7.2,0,10.8,0
	c0.6,0,1.2,0,1.8,0c0.3,0,0.6,0,0.9,0c0.1,0,0.2,0,0.3,0c0.3,0.1,0.7,0.1,0.9,0.2c0.4,0.3,0.6,0.6,0.7,1.2c0,0.2,0.1,0.7,0,0.9
	c-0.1,0.5-0.2,1-0.6,1.3c-0.2,0.1-0.4,0.3-0.7,0.3c-0.4,0.1-0.8,0.1-1.2,0.1c-0.7,0-1.3,0-2,0c-3.5,0-7,0-10.4,0c-0.8,0-1.6,0-2.4,0
	c-0.6,0-1.2,0-1.6,0.2c-0.4,0.1-0.6,0.4-0.8,0.8c-0.1,0.2-0.1,0.5-0.2,0.8c0,0.2,0,0.5,0,0.7c0,0.2,0,0.3,0.1,0.4
	c0.1,0.3,0.3,0.6,0.6,0.8c0.7,0.4,2.6,0.3,3.8,0.3c3.5,0,7,0,10.5,0c0.8,0,1.6,0,2.4,0c0.7,0,1.4,0,1.8,0.3c0.7,0.4,0.7,1.4,0.7,2.5
	c0,1,0,2,0,2.9c0,5.3,0,10.5,0,15.8c0,1.3,0,2.5,0,3.8c0,0.9,0,1.8-0.6,2.2c-0.7,0.5-2.2,0.4-3.4,0.4c-2.6,0-5.2,0-7.8,0
	c-1.1,0-2.9,0.1-3.5-0.3c-0.4-0.2-0.6-0.6-0.7-1.1c0-0.1,0-0.2,0-0.4c0-0.2-0.1-0.5,0-0.7c0.1-0.5,0.2-1,0.6-1.3
	c0.7-0.5,2-0.4,3.2-0.4c1.5,0,3.1,0,4.6,0c0.8,0,1.8,0.1,2.4-0.1c0.4-0.1,0.8-0.3,0.9-0.6c0.4-0.6,0.3-1.7,0.3-2.7c0-1.5,0-3,0-4.5
	c0-0.5,0-0.9,0-1.4c0-0.2,0-0.5,0-0.7c0-0.2,0-0.4,0-0.6c-0.1-0.5-0.3-0.9-0.7-1.1c-0.6-0.4-1.6-0.3-2.6-0.3c-1.8,0-3.6,0-5.3,0
	c-0.8,0-1.8,0.1-2.4-0.2c-0.6-0.3-0.9-0.9-0.9-1.8c0-0.3,0-0.5,0-0.7c0.1-0.5,0.4-0.9,0.8-1.1c0.7-0.3,1.7-0.2,2.6-0.2
	c1.7,0,3.4,0,5.1,0c1.7,0,2.9,0.1,3.3-1.2c0.2-0.8,0-1.9-0.4-2.3c-0.6-0.6-1.9-0.5-3.1-0.5c-2.5,0-4.9,0-7.4,0c-0.8,0-1.6,0-2.4,0
	c-0.9,0-1.7,0-2.2,0.5c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0.4-0.1,1-0.1,1.5c0,0.7,0,1.4,0,2.1c0,1.3,0,2.7,0,4c0,1.5-0.1,2.7,1.1,3.1
	c0.6,0.2,1.6,0.1,2.4,0.1c1.6,0,3.1,0,4.7,0c1.2,0,2.5-0.1,3.2,0.4c0.4,0.3,0.4,0.7,0.6,1.3c0,0.3,0,0.8,0,0.9
	c-0.1,0.4-0.1,0.6-0.3,0.8c-0.5,0.6-1.6,0.6-2.8,0.6c-1.6,0-3.3,0-5,0c-0.4,0-0.8,0-1.3,0c-0.2,0-0.5,0-0.6,0c-0.2,0-0.4,0-0.6,0
	c-0.5,0.1-0.9,0.3-1.1,0.7c-0.4,0.6-0.3,1.5-0.3,2.4c0,1.6,0,3.2,0,4.9c0,0.9-0.1,2.4,0.2,3.1c0.4,1,1.6,0.9,3,0.9
	c2.2,0,4.5,0,6.7,0c8,0,16.1,0,24.1,0c2.1,0,4.2,0,6.4,0c1,0,2.3,0.1,2.9-0.3c0.7-0.5,0.7-1.5,0.7-2.7c0-1.9,0-3.9,0-5.8
	c0-1.1,0.1-2.2-0.5-2.7c-0.5-0.5-1.6-0.5-2.7-0.5c-1.9,0-3.9,0-5.8,0c-1.1,0-2.2,0.1-2.7-0.6c-0.2-0.2-0.2-0.5-0.3-0.8
	c0-0.2-0.1-0.7,0-0.9c0.1-0.6,0.2-1.1,0.7-1.4c0.6-0.4,1.6-0.3,2.5-0.3c1.7,0,3.5,0,5.2,0c1.6,0,3,0.2,3.4-0.9
	c0.3-0.6,0.2-1.7,0.2-2.6c0-1.7,0-3.5,0-5.2c0-1.1,0.1-2.2-0.5-2.7c-0.7-0.7-2.3-0.5-3.6-0.5c-2.5,0-5,0-7.5,0c-0.5,0-1.1,0-1.6,0
	c-0.3,0-0.6,0-0.8,0c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5,0.1-0.7,0.2c-0.4,0.2-0.7,0.5-0.8,0.9c-0.1,0.2,0,0.4-0.1,0.6c0,0.1,0,0.4,0,0.6
	c0,0.2,0,0.3,0,0.5c0.1,0.5,0.4,0.9,0.9,1.1c0.6,0.3,1.8,0.2,2.6,0.2c1.8,0,3.5,0,5.3,0c1.1,0,2.3-0.1,2.7,0.6
	c0.2,0.2,0.2,0.5,0.3,0.8c0,0.2,0.1,0.7,0,0.9c-0.1,0.5-0.2,1-0.6,1.3c-0.7,0.5-2,0.4-3.2,0.4c-1.5,0-3,0-4.5,0c-0.4,0-0.8,0-1.1,0
	c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.6,0c-0.5,0.1-0.9,0.3-1.1,0.7c-0.3,0.5-0.3,1.5-0.3,2.4c0,1.6,0,3.2,0,4.7c0,0.5,0,0.9,0,1.4
	c0,0.2,0,0.5,0,0.8c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3,0.1,0.4c0.1,0.4,0.3,0.8,0.6,0.9c0.6,0.4,1.7,0.3,2.7,0.3c1.8,0,3.5,0,5.3,0
	c0.9,0,2-0.1,2.5,0.3c0.3,0.2,0.5,0.5,0.7,0.8c0.1,0.2,0,0.4,0.1,0.6c0,0.2,0,0.5,0,0.7c0,0.1,0,0.2,0,0.4c-0.1,0.4-0.3,0.8-0.6,1
	c-0.5,0.3-1.3,0.3-2.1,0.3c-1.3,0-2.6,0-3.9,0c-2,0-3.9,0-5.9,0c-1.1,0-2.2,0.1-2.8-0.4c-0.3-0.2-0.4-0.6-0.5-0.9
	c-0.1-0.4-0.1-0.8-0.1-1.3c0-0.7,0-1.4,0-2.1c0-4,0-8,0-12c0-2.5,0-5,0-7.5c0-1.2-0.1-2.6,0.4-3.3c0.6-0.8,2.6-0.6,4-0.6
	c3.6,0,7.3,0,10.9,0c0.8,0,1.5,0,2.3,0c0.6,0,1.2,0,1.6-0.2c0.4-0.2,0.7-0.5,0.8-0.9c0.1-0.2,0.1-0.5,0.1-0.7c0-0.9-0.2-1.5-0.7-1.9
	c-0.3-0.2-0.6-0.2-1-0.3c-0.1,0-0.2,0-0.3,0c-0.3-0.1-0.7,0-1,0c-0.6,0-1.3,0-1.9,0c-3.6,0-7.1,0-10.7,0c-0.7,0-1.3,0-2,0
	c-0.4,0-0.9,0-1.2-0.1c-0.3-0.1-0.5-0.2-0.7-0.3c-0.4-0.3-0.4-0.7-0.6-1.3c0-0.3,0-0.8,0-0.9c0.1-0.6,0.3-0.9,0.7-1.2
	c0.2-0.1,0.6-0.2,0.9-0.2c0.1,0,0.2,0,0.3,0c0.3,0,0.6,0,0.9,0c0.6,0,1.2,0,1.8,0c3.6,0,7.2,0,10.8,0c0.7,0,1.4,0,2,0
	c0.5,0,1,0,1.4-0.1c0.2-0.1,0.5-0.2,0.6-0.4c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.4,0.1-1,0.1-1.5c0-0.7,0-1.4,0-2.2c0-1.3,0-2.7,0-4
	c0-1.5,0.1-2.7-1.1-3.1c-0.7-0.2-1.8-0.1-2.6-0.1c-1.7,0-3.4,0-5.2,0c-1,0-2,0-2.6-0.4c-0.3-0.2-0.4-0.6-0.5-1c0-0.2-0.1-0.7,0-0.9
	c0.1-0.6,0.2-1.1,0.7-1.4c0.6-0.4,1.6-0.3,2.6-0.3c1.8,0,3.6,0,5.4,0c0.8,0,1.8,0.1,2.4-0.2c0.4-0.2,0.7-0.5,0.8-0.9
	c0.3-0.8,0.1-2-0.4-2.4c-0.6-0.6-2.3-0.5-3.5-0.5c-2.7,0-5.3,0-8,0c-1.2,0-2.7-0.1-3.4,0.4c-0.6,0.5-0.6,1.6-0.6,2.7
	c0,1.7,0,3.3,0,5c0,0.4,0,0.9,0,1.3c0,0.2,0,0.5,0,0.6c0,0.2,0,0.4,0,0.6c0.1,0.5,0.3,0.9,0.7,1.1c0.6,0.4,1.9,0.3,2.9,0.3
	c1.7,0,3.4,0,5.2,0c0.9,0,1.9-0.1,2.5,0.3c0.5,0.3,0.5,0.7,0.7,1.4c0,0.2,0,0.5,0,0.7c0,0.1,0,0.2,0,0.4c-0.1,0.4-0.2,0.7-0.5,0.9
	c-0.7,0.5-2.1,0.4-3.4,0.4c-2.5,0-5,0-7.6,0c-0.6,0-1.2,0-1.8,0c-0.7,0-1.3,0-1.8-0.2c-0.3-0.1-0.5-0.4-0.7-0.6
	c-0.3-0.4-0.3-1.1-0.3-1.7c0-0.9,0-1.8,0-2.6c0-3.6,0-7.2,0-10.8c0-1-0.1-2.5,0.2-3.2c0.2-0.5,0.6-0.7,1.1-0.8
	c0.4-0.1,1.1-0.1,1.6-0.1c1,0,2,0,3,0c3.2,0,6.5,0,9.7,0c0.5,0,1.1,0,1.6,0c0.3,0,0.6,0,0.8,0c0.1,0,0.2,0,0.3,0
	c0.3,0,0.5-0.1,0.7-0.2c0.7-0.3,1-1,0.9-2.1c0-0.3-0.1-0.6-0.2-0.8c-0.4-1-1.6-0.9-3.1-0.9c-2.4,0-4.7,0-7,0
	C131.1,84,120.5,84,109.9,84z M9.7,84c-0.1,0-0.5,0.1-0.6,0.1c-0.3,0.1-0.6,0.3-0.8,0.6c-0.2,0.3-0.2,0.6-0.3,1c0,0.1,0,0.2,0,0.3
	c0,0.3,0,0.6,0,0.9c0,0.6,0,1.2,0,1.8c0,3.1,0,6.2,0,9.3c0,1,0,2,0,3c0,0.6,0,1.2,0.1,1.7c0.1,0.5,0.4,0.9,0.9,1.1
	c0.4,0.2,1.1,0.2,1.7,0.2c0.9,0,1.8,0,2.6,0c0.7,0,1.3,0,1.8,0.2c0.5,0.2,0.7,0.6,0.8,1.1c0.1,0.2,0.1,0.8,0,1.1
	c0,0.2,0,0.4-0.1,0.6c-0.2,0.5-0.5,0.8-1,1c-0.4,0.1-1.1,0.1-1.6,0.1c-0.9,0-1.8,0-2.6,0c-1.1,0-1.9,0.1-2.3,0.7
	c-0.3,0.5-0.3,1.2-0.3,2c0,1.1,0,2.1,0,3.2c0,4.3,0,8.6,0,12.8c0,6.5,0,13,0,19.5c0,1,0,2.1,0,3.1c0,0.8,0,1.5,0.3,2
	c0.2,0.4,0.6,0.5,1,0.6c0.2,0,0.7,0.1,0.9,0.1c0.2,0,0.3,0,0.5-0.1c0.4-0.1,0.8-0.3,0.9-0.6c0.3-0.5,0.3-1.5,0.3-2.3
	c0-1.5,0-3.1,0-4.6c0-7.8,0-15.6,0-23.5c0-1.9,0-3.7,0-5.6c0-1-0.1-2,0.3-2.6c0.3-0.5,0.7-0.5,1.4-0.7c0.3,0,0.8,0,0.9,0
	c0.4,0.1,0.8,0.2,1,0.5c0.4,0.6,0.4,1.7,0.4,2.8c0,1.3,0,2.6,0,4c0,8.3,0,16.6,0,24.9c0,1.6,0,3.2,0,4.7c0,1,0,1.9,0.4,2.4
	c0.5,0.7,2.5,0.8,3.1,0.1C20,151,20,150,20,148.9c0-1.8,0-3.6,0-5.3c0-7.5,0-14.9,0-22.4c0-2,0-3.9,0-5.9c0-1-0.1-2.1,0.3-2.7
	c0.3-0.5,0.7-0.5,1.4-0.7c0.2,0,0.5,0,0.7,0c0.1,0,0.2,0,0.4,0c0.4,0.1,0.7,0.2,0.9,0.5c0.4,0.6,0.4,1.6,0.4,2.7c0,1.8,0,3.6,0,5.3
	c0,8.1,0,16.2,0,24.3c0,1.2,0,2.5,0,3.7c0,1.1-0.1,2.4,0.5,2.9c0.3,0.3,0.6,0.3,1.1,0.4c0.2,0,0.5,0.1,0.7,0c0.2,0,0.4,0,0.6-0.1
	c1.2-0.4,1.1-1.6,1.1-3.2c0-2.2,0-4.5,0-6.7c0-7.7,0-15.5,0-23.2c0-2.2,0-4.4,0-6.7c0-1,0.1-2.5-0.2-3.2c-0.4-1-1.4-0.9-2.8-0.9
	c-0.8,0-1.6,0-2.4,0c-0.5,0-1,0-1.4-0.1c-0.6-0.2-1-0.5-1.2-1c-0.1-0.2,0-0.4-0.1-0.6c-0.1-0.3,0-0.8,0-1.1c0.1-0.5,0.4-0.9,0.8-1.1
	s1.1-0.2,1.7-0.2c0.8,0,1.7,0,2.6,0c0.9,0,1.8,0,2.3-0.5c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.4,0.1-0.9,0.1-1.5c0-0.7,0-1.4,0-2
	c0-3.6,0-7.3,0-10.9c0-0.5,0-1.1,0-1.6c0-0.3,0-0.6,0-0.8c0-0.1,0-0.2,0-0.3c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.4-0.4-0.6-0.8-0.8
	C26.8,84,26.5,84,26.2,84c-0.3-0.1-0.8,0-0.9,0.1c-0.5,0.1-0.8,0.3-1,0.6c-0.4,0.7-0.3,2.2-0.3,3.2c0,2.6,0,5.3,0,7.9
	c0,1.1,0.1,2.7-0.3,3.4c-0.3,0.5-0.7,0.5-1.4,0.7c-0.2,0-0.5,0-0.7,0c-0.1,0-0.2,0-0.4,0c-0.4-0.1-0.8-0.3-1-0.6
	c-0.4-0.7-0.3-2.3-0.3-3.5c0-2.7,0-5.4,0-8.1c0-1,0.1-2.3-0.3-3c-0.2-0.4-0.6-0.5-1-0.6c-0.2,0-0.7-0.1-0.9-0.1
	c-0.3,0.1-0.5,0.1-0.8,0.2C15.8,84.6,16,86,16,87.5c0,2.9,0,5.7,0,8.6c0,1.3,0.2,2.9-0.6,3.5c-0.2,0.1-0.4,0.3-0.7,0.3
	c-0.1,0-0.2,0-0.4,0c-0.2,0-0.6,0-0.8,0c-0.5-0.1-0.9-0.3-1.2-0.7c-0.5-0.7-0.3-2.4-0.3-3.5c0-2.7,0-5.4,0-8.1
	c0-1.2,0.1-2.7-0.5-3.3C11.1,84.1,10.5,84,9.7,84z M85.7,84c-0.1,0-0.5,0.1-0.6,0.1c-0.3,0.1-0.6,0.3-0.8,0.6
	c-0.2,0.3-0.2,0.6-0.3,1c0,0.1,0,0.2,0,0.3c0,0.3,0,0.6,0,0.9c0,0.6,0,1.2,0,1.8c0,3.1,0,6.2,0,9.3c0,1,0,2,0,3c0,0.6,0,1.2,0.1,1.7
	c0.1,0.5,0.4,0.9,0.9,1.1c0.4,0.2,1.1,0.2,1.7,0.2c0.9,0,1.8,0,2.6,0c0.7,0,1.3,0,1.8,0.2c0.5,0.2,0.7,0.6,0.8,1.1
	c0.1,0.2,0.1,0.8,0,1.1c0,0.2,0,0.4-0.1,0.6c-0.2,0.5-0.5,0.8-1,1c-0.4,0.1-1.1,0.1-1.6,0.1c-0.9,0-1.8,0-2.6,0
	c-1.1,0-1.9,0.1-2.3,0.7c-0.3,0.5-0.3,1.2-0.3,2c0,1.1,0,2.1,0,3.2c0,4.3,0,8.6,0,12.8c0,6.5,0,13,0,19.5c0,1,0,2.1,0,3.1
	c0,0.8,0,1.5,0.3,2c0.2,0.4,0.6,0.5,1,0.6c0.2,0,0.7,0.1,0.9,0.1c0.2,0,0.3,0,0.5-0.1c0.4-0.1,0.8-0.3,0.9-0.6
	c0.3-0.5,0.3-1.5,0.3-2.3c0-1.5,0-3.1,0-4.6c0-7.8,0-15.6,0-23.5c0-1.9,0-3.7,0-5.6c0-1-0.1-2,0.3-2.6c0.3-0.5,0.7-0.5,1.4-0.7
	c0.3,0,0.8,0,0.9,0c0.4,0.1,0.8,0.2,1,0.5c0.4,0.6,0.4,1.7,0.4,2.8c0,1.3,0,2.6,0,4c0,8.3,0,16.6,0,24.9c0,1.6,0,3.2,0,4.7
	c0,1,0,1.9,0.4,2.4c0.5,0.7,2.5,0.8,3.1,0.1c0.5-0.5,0.5-1.5,0.5-2.5c0-1.8,0-3.6,0-5.3c0-7.5,0-14.9,0-22.4c0-2,0-3.9,0-5.9
	c0-1-0.1-2.1,0.3-2.7c0.3-0.5,0.7-0.5,1.4-0.7c0.2,0,0.5,0,0.7,0c0.1,0,0.2,0,0.4,0c0.4,0.1,0.7,0.2,0.9,0.5
	c0.4,0.6,0.4,1.6,0.4,2.7c0,1.8,0,3.6,0,5.3c0,8.1,0,16.2,0,24.3c0,1.2,0,2.5,0,3.7c0,1.1-0.1,2.4,0.5,2.9c0.3,0.3,0.6,0.3,1.1,0.4
	c0.2,0,0.5,0.1,0.7,0c0.2,0,0.4,0,0.6-0.1c1.2-0.4,1.1-1.6,1.1-3.2c0-2.2,0-4.5,0-6.7c0-7.7,0-15.5,0-23.2c0-2.2,0-4.4,0-6.7
	c0-1,0.1-2.5-0.2-3.2c-0.4-1-1.4-0.9-2.8-0.9c-0.8,0-1.6,0-2.4,0c-0.5,0-1,0-1.4-0.1c-0.6-0.2-1-0.5-1.2-1c-0.1-0.2,0-0.4-0.1-0.6
	c-0.1-0.3,0-0.8,0-1.1c0.1-0.5,0.4-0.9,0.8-1.1c0.5-0.2,1.1-0.2,1.7-0.2c0.8,0,1.7,0,2.6,0c0.9,0,1.8,0,2.3-0.5
	c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.4,0.1-0.9,0.1-1.5c0-0.7,0-1.4,0-2c0-3.6,0-7.3,0-10.9c0-0.5,0-1.1,0-1.6c0-0.3,0-0.6,0-0.8
	c0-0.1,0-0.2,0-0.3c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.4-0.4-0.6-0.8-0.8c-0.2-0.1-0.5-0.1-0.8-0.2s-0.8,0-0.9,0.1
	c-0.5,0.1-0.8,0.3-1,0.6c-0.4,0.7-0.3,2.2-0.3,3.2c0,2.6,0,5.3,0,7.9c0,1.1,0.1,2.7-0.3,3.4c-0.3,0.5-0.7,0.5-1.4,0.7
	c-0.2,0-0.5,0-0.7,0c-0.1,0-0.2,0-0.4,0c-0.4-0.1-0.8-0.3-1-0.6c-0.4-0.7-0.3-2.3-0.3-3.5c0-2.7,0-5.4,0-8.1c0-1,0.1-2.3-0.3-3
	c-0.2-0.4-0.6-0.5-1-0.6c-0.2,0-0.7-0.1-0.9-0.1c-0.3,0.1-0.5,0.1-0.8,0.2C91.8,84.6,92,86,92,87.5c0,2.9,0,5.7,0,8.6
	c0,1.3,0.2,2.9-0.6,3.5c-0.2,0.1-0.4,0.3-0.7,0.3c-0.1,0-0.2,0-0.4,0c-0.2,0-0.6,0-0.8,0c-0.5-0.1-0.9-0.3-1.2-0.7
	c-0.5-0.7-0.3-2.4-0.3-3.5c0-2.7,0-5.4,0-8.1c0-1.2,0.1-2.7-0.5-3.3C87.1,84.1,86.5,84,85.7,84z"
            transform="translate(0 0)"
          />
        )}
      </Svg>
    </>
  )
}

export default Logo

const Svg = styled.svg`
  width: ${({ size }) => size}em;
  height: ${({ size }) => size}em;
  transition: width 0.25s, height 0.25s;
`

const Path = styled.path`
  fill: var(--${({ color }) => color});
  fill-rule: evenodd;
  clip-rule: evenodd;
  transition: fill 0.25s;
`
