import imgBezierCurve1 from "../../../../content/posts/2019/building-levels-part-3/bezier-curve-1.jpg";
import imgBezierCurve2 from "../../../../content/posts/2019/building-levels-part-3/bezier-curve-2.jpg";
import imgCurveDiagram from "../../../../content/posts/2019/building-levels-part-3/curve-diagram.jpg";
import imgCurveGrid from "../../../../content/posts/2019/building-levels-part-3/curve-grid.png";
import * as React from 'react';
export default {
  imgBezierCurve1,
  imgBezierCurve2,
  imgCurveDiagram,
  imgCurveGrid,
  React
};