import imgOriginal from "../../../../content/posts/2019/ship-design/original.jpg";
import imgOriginalStates from "../../../../content/posts/2019/ship-design/original-states.png";
import imgGroup1 from "../../../../content/posts/2019/ship-design/group-1.jpg";
import imgGroup2 from "../../../../content/posts/2019/ship-design/group-2.jpg";
import imgGroup3 from "../../../../content/posts/2019/ship-design/group-3.jpg";
import imgFinal from "../../../../content/posts/2019/ship-design/final.jpg";
import imgFinalVector from "../../../../content/posts/2019/ship-design/final-vector.jpg";
import * as React from 'react';
export default {
  imgOriginal,
  imgOriginalStates,
  imgGroup1,
  imgGroup2,
  imgGroup3,
  imgFinal,
  imgFinalVector,
  React
};